import { render, staticRenderFns } from "./CookiePolicy.html?vue&type=template&id=6a3c976f&scoped=true&external"
import script from "./CookiePolicy.vue?vue&type=script&lang=js"
export * from "./CookiePolicy.vue?vue&type=script&lang=js"
import style0 from "./CookiePolicy.scss?vue&type=style&index=0&id=6a3c976f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3c976f",
  null
  
)

export default component.exports