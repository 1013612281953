<template src="./PatientCases.html"></template>

<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import Vue from "vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import gsap from 'gsap';
import Flip from 'gsap/Flip';
//import { document } from "pdfkit/js/page";

gsap.registerPlugin(Flip);

Vue.component('v-select', vSelect)
export default {
  name: "PatientCases",
  components: {
    InnerHeroSection,
  },
  data() {
    return {
     selectedValue: {
          id:'All', value:'Show All'
        }, 
      HeroBoxData: {
          title: "PATIENT IDENTIFICATION",
      },
      Pagedata:{
        title:"Explore patient profiles below to help identify appropriate patients for COMBOGESIC® IV",
        description:"COMBOGESIC® IV is indicated in adults (age ≥18 years) where an intravenous route of administration is considered clinically necessary for the relief of mild to moderate pain or for the management of moderate to severe pain as an adjunct to opioid analgesics.<sup>1</sup> The application of COMBOGESIC® IV in diverse patient populations is an important development in optimizing acute pain management.",
        ProfilesTitle:"Appropriate Patients for Treatment With COMBOGESIC® IV",
        Profiles:[
          {
            class:"mild",
            name:"Ana",
            shortdescription:"Elderly Patient with Multiple Comorbidities",
            issue:"Mild to Moderate Pain",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-ana-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-ana.jpg"),
              name:"Ana",
              info:"68-year-old volunteer coordinator, loves spending time in her quilting group",
              issue:"Mild to Moderate Pain",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Ana's injury is causing her acute moderate pain, affecting her ability to move comfortably","She presents to ambulatory care with moderate pain due to a hip labral tear"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Previously diagnosed with obesity, type 2 diabetes, and nonalcoholic fatty liver disease","Her orthopedic surgeon recommended a short-term IV analgesic based on her history of liver disease and difficulty metabolizing oral medications"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered and added to her treatment plan"]
                }
              ],
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"mild",
            name:"Michael",
            shortdescription:"Unable to Take Oral Medication",
            issue:"Mild to Moderate Pain",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-michael-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-michael.jpg"),
              name:"Michael",
              info:"55-year-old construction worker, enjoys playing basketball recreationally",
              issue:"Mild to Moderate Pain",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["History of severe motility disorder in his gastrointestinal tract that has progressively worsened over the years","Presents to the emergency department in the hospital for emergency bowel obstruction surgery"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["He is currently nauseous and experiencing acute postoperative pain","Michael is unable to take oral medication at this time so his general surgeon determined a short course of IV analgesia was clinically necessary"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered and added to his treatment plan"]
                }
              ],
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"mild",
            name:"Rachel",
            shortdescription:"Unable to Take Oral Medication",
            issue:"Mild to Moderate Pain",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-rachel-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-rachel.jpg"),
              name:"Rachel",
              info:"52-year-old high school teacher, devoted mother of two children",
              issue:"Mild to Moderate Pain",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Recently diagnosed with stage II breast cancer","Treated with neoadjuvant chemotherapy at an ambulatory infusion center before having a mastectomy as part of her treatment plan"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Experiencing acute pain following surgery and recent radiation treatment that she received","Unable to take oral medication due to significant treatment-induced nausea and vomiting, along with generalized body aches and discomfort","Her medical oncologist determined it is clinically necessary to administer short-course IV analgesic therapy to treat her acute pain"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered and added to her treatment plan"]
                }
              ],
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"severe",
            name:"Emily",
            shortdescription:"Unable to Take Oral Medication",
            issue:"Moderate to Severe Pain*",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-emily-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-emily.jpg"),
              name:"Emily",
              info:"35-year-old graphic designer, enjoys hiking on weekends",
              issue:"Moderate to Severe Pain*",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Previously diagnosed with Crohn's disease, impacting her ability to absorb oral medications but has never required surgical intervention","Presents to the emergency department with moderate acute lower back pain following a lifting injury"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Pain is described as sharp and stabbing, significantly limiting her ability to perform daily activities","The attending hospitalist determined it was clinically necessary to prescribe IV analgesics for a short duration based on her past medical history and malabsorption issues"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered to supplement her opioid-based infusion"]
                }
              ],
              footNote: "*As an adjunct to opioids",
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"severe",
            name:"James",
            shortdescription:"Need to Mitigate Opioid Usage",
            issue:"Moderate to Severe Pain*",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-james-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-james.jpg"),
              name:"James",
              info:"28-year-old bike messenger in the city, outdoor enthusiast",
              issue:"Moderate to Severe Pain*",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Sustained multiple fractures, including a femoral shaft fracture, in a cycling accident","Presents to the emergency department with severe pain, swelling, and restricted mobility in his lower extremity"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Experiencing severe pain, despite the emergency medicine specialist determining it was clinically necessary to manage his pain with a short course of intravenous opioids","His breakthrough pain remains uncontrolled due to opioid-related side effects such as nausea and vomiting"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered and added to his treatment plan"]
                }
              ],
              footNote: "*As an adjunct to opioids",
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"severe",
            name:"John",
            shortdescription:"Elderly Patient with Multiple Comorbidities",
            issue:"Moderate to Severe Pain*",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-john-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-john.jpg"),
              name:"John",
              info:"72-year-old retired veteran, enjoys spending time with his grandchildren",
              issue:"Moderate to Severe Pain*",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Past medical history of chronic heart failure, severe hypertension, and Parkinson's disease","Presents to a satellite emergency department (SED) with significant pain in his hip and shoulder due to a recent fall"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["X-rays indicate multiple fractures causing significant swelling and pain, exacerbating his underlying conditions","John is having difficulty swallowing leading the critical care doctor to recommend a short course of IV analgesics"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered as an addition to his opioid-based infusion"]
                }
              ],
              footNote: "*As an adjunct to opioids",
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"mild",
            name:"Amy",
            shortdescription:"Need to Mitigate Opioid Usage",
            issue:"Mild to Moderate Pain",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-amy-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-amy.jpg"),
              name:"Amy",
              info:"57-year-old freelance writer, enjoys tending to her garden",
              issue:"Mild to Moderate Pain",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["History of low tolerance to opioid analgesia due to adverse effects and previous complications","Admitted to the emergency department with moderate flank pain and fever due to a kidney cyst"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Amy's kidney cyst was determined to be infected causing her significant acute pain and discomfort, affecting her ability to move comfortably","The attending emergency room specialist prescribed a short-term IV analgesic to treat her pain and minimize the risk of unwanted side effects of opioids"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered and added to her treatment plan"]
                }
              ],
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"severe",
            name:"Henry",
            shortdescription:"Unable to Take Oral Medication",
            issue:"Moderate to Severe Pain*",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-henry-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-henry.jpg"),
              name:"Henry",
              info:"45-year-old civil engineer, enjoys woodworking in his free time",
              issue:"Moderate to Severe Pain*",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Presents to the emergency department with severe pain from a laceration due to a woodworking accident","He has difficulty swallowing due to nausea and vomiting, making it challenging for him to take oral medication"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Henry's severe pain is causing him great distress and increasing his risk for other complications","His inability to take oral medications drives the emergency department intensivist to recommend short-term IV analgesia"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered as an adjunct to his opioid-based infusion"]
                }
              ],
              footNote: "*As an adjunct to opioids",
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          },
          {
            class:"mild",
            name:"Sarah",
            shortdescription:"Need to Mitigate Opioid Usage",
            issue:"Mild to Moderate Pain",
            image:require("@/Assets/Img/PatientModals/SM/patient-modal-img-sarah-sm.jpg"),
            popupdata:{
              image:require("@/Assets/Img/PatientModals/patient-modal-img-sarah.jpg"),
              name:"Sarah",
              info:"38-year-old yoga instructor, leads an active lifestyle",
              issue:"Mild to Moderate Pain",
              description:[
                {
                  title:"MEDICAL HISTORY:",
                  listing:["Underwent a laparoscopic cholecystectomy at an ambulatory surgery center due to acute cholecystitis","History of mild-to-moderate pain sensitivity","Low tolerance for opioids due to past history of adverse reactions"]
                },
                {
                  title:"CURRENT CONDITION:",
                  listing:["Experiencing nausea and postoperative pain, primarily localized around the surgical site","Her general surgeon determined a short-term IV analgesic would quickly aid in her pain while leaving her stomach issues unaffected"]
                },
                {
                  title:"TREATMENT:",
                  listing:["COMBOGESIC® IV, an IV fixed dose combination (FDC) (acetaminophen and ibuprofen) non-opioid analgesia in a single formulation for adult pain management was administered and added to her treatment plan"]
                }
              ],
              footNote: "",
              btntile:"View the COMBOGESIC<sup>®</sup> IV pain model<sup>&copy;</sup>",
              link:"/#/analgesic-efficacy/#pain-model"
            }
          }
        ],
        learnmore:{
          title:"Patient Screening Tool",
          description:"Download a questionnaire to help you identify appropriate patients for COMBOGESIC® IV",
          link:"/#/resources/#product-ordering",
          image:require("@/Assets/Img/Icons/icon-50-percent.svg")
        }
      },
      options: [
        {
          id:'All', value:'Show All'
        },
         {
          id:'mild', value:'Mild to Moderate Pain'
        },
         {
          id:'severe', value:'Moderate to Severe Pain*'
        }
      ]
    }
  },
  methods:{
    updateAccordionState() {
      const isMobileView = window.innerWidth < 1023;
      const accordionBtns = document.querySelectorAll(".infoBox__title");
      const panels = document.querySelectorAll(".panel");

      panels.forEach((panel, index) => {
        const isActive = accordionBtns[index].classList.contains("active");

        if (isMobileView) {
          panel.style.maxHeight = isActive ? panel.scrollHeight + "px" : "0px";
          panel.style.overflow = "hidden";

          if (!accordionBtns[index].hasEventListener) {
            accordionBtns[index].addEventListener("click", this.toggleAccordion);
            accordionBtns[index].hasEventListener = true; 
          }
        } else {
          panel.style.maxHeight = "";
          panel.style.overflow = "";
          accordionBtns[index].classList.remove("active");
          if (accordionBtns[index].hasEventListener) {
            accordionBtns[index].removeEventListener("click", this.toggleAccordion);
            accordionBtns[index].hasEventListener = false;
          }
        }
      });
    },

    toggleAccordion(event) {
      const panel = event.currentTarget.nextElementSibling;
      event.currentTarget.classList.toggle("active");
      panel.style.maxHeight = panel.style.maxHeight === "0px" || panel.style.maxHeight === "" ? panel.scrollHeight + "px" : "0px";
    },

    patientPopup(popupid){
      document.getElementById(popupid).classList.add("active");
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    },
    patientPopupcloss(popupid){
      document.getElementById(popupid).classList.remove("active");
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    },
    updateFilters() {
      this.$nextTick(() => {
        const items = Array.from(this.$el.querySelectorAll('.item'));
        const state = Flip.getState(items);
        console.log(state);
        console.log(this.selectedValue.id);
        items.forEach((item) => {
          if (this.selectedValue.id === 'All' || item.classList.contains(this.selectedValue.id)) {
            item.style.display = 'block';
          } else {
            item.style.display = 'none';
          }
          if(this.selectedValue.id == "mild"){
              document.getElementsByClassName("starPatients")[0].style.display = "none";
          }else{
            document.getElementsByClassName("starPatients")[0].style.display = "block";
          }
        });

        Flip.from(state, {
          duration: 0.7,
          scale: true,
          ease: 'power1.inOut',
          stagger: 0.08,
          absolute: true,
          onEnter: (elements) =>
            gsap.fromTo(
              elements,
              { opacity: 0, scale: 0 },
              { opacity: 1, scale: 1, duration: 1 }
            ),
          onLeave: (elements) =>
            gsap.to(elements, { opacity: 0, scale: 0, duration: 1 })
        });
      });
    },
    resetFilter() {
      this.$nextTick(() => {
        const items = Array.from(this.$el.querySelectorAll('.item'));
        const state = Flip.getState(items);

        items.forEach((item) => {
          item.style.display = 'block';
        });
        
        document.getElementsByClassName("starPatients")[0].style.display = "block";

        Flip.from(state, {
          duration: 0.7,
          scale: true,
          ease: 'power1.inOut',
          stagger: 0.08,
          absolute: true,
          onEnter: (elements) =>
            gsap.fromTo(
              elements,
              { opacity: 0, scale: 0 },
              { opacity: 1, scale: 1, duration: 1 }
            ),
          onLeave: (elements) =>
            gsap.to(elements, { opacity: 0, scale: 0, duration: 1 })
        });

        this.selectedValue = 'Show All';
      });
    },
  },

  mounted() {
    this.updateAccordionState();
    window.addEventListener("resize", this.updateAccordionState);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateAccordionState);
  }
};
</script>

<style lang="scss" src="./PatientCases.scss" scoped></style>