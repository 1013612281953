<template>
  <div class="headerTopWrap">
    <div :class="{ headerTop: true, '': this.isScrolled }">
      <div class="headerTop__tagline">
        This site is intended for U.S. residents age 18 and over
      </div>
      <div class="headerTop__links">
        <div class="pdfLinks">
          <a :href="urls.PI.link" target="_blank"><img src="@/Assets/Img/Icons/icon-pdf-blue.svg" alt="PDF Icon" />{{ urls.PI.text }}</a>
          <a href="/#/contact-us"><img src="@/Assets/Img/Icons/icon-connect.svg" alt="Connect Icon" />Connect With Hikma</a>          
        </div>
        <div class="btnOrder"><a href="/#/resources/#product-ordering"><img src="@/Assets/Img/Icons/icon-cart.svg" alt="Cart Icon" />Order Product Today</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderTop",
  components: {},
  data() {
    return {
      isOpen: false,
      isScrolled: false,
      options: {
        container: "body",
        easing: "ease-in",
        offset: -150,
      },
    };
  },
  computed: {
    ...mapState(["userAgent", "urls"]),
    routes() {
      return this.$router.options.routes;
    },
  },
  methods: {},
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" src="./HeaderTop.scss" scoped>
</style>
