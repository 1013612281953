<template src="./Resources.html"></template>

<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import Table from "@/Components/Tables/Table";
export default {
  name: "Resources",
  components: {
    InnerHeroSection,
    Table
  },
  data() {
    return {
      HeroBoxData: {
          title: "Resources",
      },
      resourcesData:{
        title: "Educational Materials and Tools to Support Your Clinical Decision Making",
        description: "Empower your clinical decisions with our evidence-based resources and latest medical insights for the treatment of adult patients with acute pain.",
        BoxTitle: "Treatment Information",
        BoxData:[
          {
            title: "Healthcare Providers Brochure",
            description: "Download this brochure to review the highlights of COMBOGESIC® IV therapy",
            link: '#',
          }
        ],
        purchaseInfoTitle: "COMBOGESIC® IV Purchasing Information",
        purchaseInfoImage: require("@/Assets/Img/Resources/img-purchasing-info.png"),
        tableTitle: "Product Description",
        tableDes: "Clear, Colorless Solution",
        link: require("@/Assets/PDF/W25-537_Combogesic-IV_SellSheet_Digital_8.5x11_v5.pdf"),
        filename: "Combogesic-IV-SellSheet-Digital",
        footnote: "<strong>Note:</strong> COMBOGESIC® IV comes in a 10-vial carton. Minimum order quantity is one carton.",
        newsBoxTitle: "EXCITING NEWS: <span class='nowrap'>Our e-commerce</span> site is <span class='nowrap'>almost here!</span>",
        newsBoxDes: "Our e-commerce site is launching soon for direct purchases. In the meantime, visit your local distributor for immediate availability of COMBOGESIC® IV. Stay tuned for more updates—we look forward to serving you soon!",
        learnmore:{
          title: "Request More Information From Hikma",
          link: "/#/contact-us",
          image: require("@/Assets/Img/Resources/img-purchasing-info.png")
        },

      },
      TableData1: {
        class: "tableContainer--productDescription",
        tableTitle: "",
        thead: [
          {
            className: "tr-rowBlue",
            columns: [
              
              { text: 'NDC Number', className:'leftBorderRadius text-left' },
              { text: 'Total Drug Content (Concentration)' },
              { text: 'Fill Volume' },
              { text: 'Unit Size'},
              { text: 'Pack Size', className:'rightBorderRadius'},
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: '0143-9150-10', className:'text-left' }, { text: '1,000 mg/300 mg per 100 mL <br /> (10 mg/3 mg per mL)' }, { text: '100 mL' }, { text: '100 mL' }, { text: '10 vials' },
            ],
          },
          
        ]
      },
      TableData2: {
        class: "tableContainer--productDescription",
        tableTitle: "",
        thead: [
          {
            className: "tr-rowBlue",
            columns: [
              
              { text: 'Product Description', className:'leftBorderRadius' },
              { text: 'NDC Number', className:'rightBorderRadius' },
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: 'Clear, Colorless Solution' }, { text: '0143-9150-10' },
            ],
          },
          
        ]
      },
      TableData3: {
        class: "tableContainer--productDescription",
        tableTitle: "",
        thead: [
          {
            className: "tr-rowBlue",
            columns: [
              
              { text: 'Total Drug Content (Concentration)', className:'BothBorderRadius' },
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: '1,000 mg/300 mg per 100 mL <br /> (10 mg/3 mg per mL)' },
            ],
          },
          
        ]
      },
      TableData4: {
        class: "tableContainer--productDescription",
        tableTitle: "",
        thead: [
          {
            className: "tr-rowBlue",
            columns: [
              
              { text: 'Fill Volume', className:'leftBorderRadius text-center' },
              { text: 'Unit Size' },
              { text: 'Pack Size',  className:'rightBorderRadius' },
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: '100 mL', className:'text-center' },
              { text: '100 mL' },
              { text: '10 vials' },
            ],
          },
          
        ]
      },
    }
  }
};
</script>

<style lang="scss" src="./Resources.scss" scoped></style>