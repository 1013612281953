<template src="./Home.html"></template>

<script>
import { mapState } from "vuex";
import ScrollDepthAnalytics from "@/mixins/ScrollDepthAnalytics";
import HeroeBoxSection from "@/Components/Blocks/HeroeBoxSection/HeroeBoxSection";
import AboutProductSection from "@/Components/Blocks/AboutProductSection/AboutProductSection";
import HomePageNavSection from "@/Components/Blocks/HomePageNavSection/HomePageNavSection";

export default {
  name: "HomePage",
  components: {
    HeroeBoxSection,
    AboutProductSection,
    HomePageNavSection,
  },
  mixins: [ScrollDepthAnalytics],
  data() {
    return {
      HeroBoxData: {
          title:"HARNESS THE <span class='text-teal'>POWER OF SYNERGY<sup>&trade;</sup></span>",
          titleSmall: "acetaminophen + ibuprofen = COMBOGESIC<sup>®</sup> IV",
          subtitle:"AN ADVANCED APPROACH TO MULTIMODAL ANALGESIA",
          description:"IV Fixed Dose Combination (FDC)  (acetaminophen and ibuprofen) <br/> Non-Opioid Analgesia in a Single Formulation for Adult Pain Management",
          colData :[
            {
              title: "MULTIMODAL ANALGESIA",
              description:"Discover an advanced approach that harnesses synergistic effects to provide optimal pain relief<sup>1</sup>",
              link:"/#/pain-management/#multimodal-analgesia",
              icon : require("@/Assets/Img/Icons/icon-multimodal-analgesia.svg"),
            },
            {
              title: "ANALGESIC EFFICACY",
              description:"View pivotal study data including superior analgesic efficacy and comparable safety in common AEs<sup>1</sup>",
              link:"#/analgesic-efficacy/#superior-efficacy",
              icon : require("@/Assets/Img/Icons/icon-analgesic-efficacy.svg"),
            },
            // For future reference
            // {
            //   title: "PATIENT IDENTIFICATION",
            //   description:"Explore patient profiles to help identify appropriate patients for COMBOGESIC® IV",
            //   link:"/#/",
            //   icon : require("@/Assets/Img/Icons/icon-patient-identification.svg"),
            // },
          ]
      },
      ProductData:{
        title:"COMBOGESIC® IV: an advanced approach to multimodal analgesia",
        description:"COMBOGESIC® IV is indicated in adults where an IV route of administration is considered clinically necessary for<sup>1</sup>:",
        listing:["Relief of mild to moderate pain","Management of moderate to severe pain as an adjunct to opioid analgesics"],
        buttontitle:"HOW TO ORDER COMBOGESIC<sup>®</sup> IV",
        Link:"/#/resources/#product-ordering",
        image : require("@/Assets/Img/ProductShots/vial-box-combo-lg.png"),
        imagetitle:"Available in packs of 10 vials.",
        imageDescription:"COMBOGESIC® IV: fixed-dose combination (FDC) of 1,000 mg of acetaminophen and 300 mg of ibuprofen (sodium dihydrate) in a 100 mL solution."
      },
      HomePageNavData:[
         {
          title: "QUESTIONS ABOUT COMBOGESIC® IV?",
          description: "Find answers to frequently asked questions and more.",
          link:"/#/faqs",
          linkText: "VIEW FAQS",
          icon : require("@/Assets/Img/Icons/icon-questions.svg"),
        },
        {
          title: "RESOURCES FOR COMBOGESIC® IV",
          description: "Find downloadable educational materials and tools.",
          link:"/#/resources",
          linkText: "Explore Resources",
          icon : require("@/Assets/Img/Icons/icon-resources.svg"),
        },
        // For future reference
        // {
        //   title: "VIDEO LIBRARY",
        //   description: "Access the COMBOGESIC IV® video library.",
        //   link:"#",
        //   linkText: "watch videos",
        //   icon : require("@/Assets/Img/Icons/icon-youtube.svg"),
        // },
      ]
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "animationsData",
      "controllerExist",
      "mainMenu",
      "activeSection",
    ]),
    isIE() {
      return this.userAgent.browser.name === "ie";
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  methods: {
    clickAnchor(anchor, offset) {
      if (typeof offset !== "undefined") {
        this.$scrollTo(anchor, 750, {
          container: "body",
          easing: "ease-in",
          offset: offset,
        });
      } else {
        this.$scrollTo(anchor, 750, this.options);
      }
    },
  },
  mounted() {
    this.initScrollDepthAnalytics();
  },
};
</script>

<style lang="scss" src="./Home.scss" scoped></style>