<template>
  <div :class="{
    isi: true,
    'isi-static': static,
    'isi-hidden': hideElement,
    'isi-opened': openedIsi && !hideElement,
    'isi-opened-menu': mobileMenuState,
    'isi-fixed': fixed,
  }">
    <div class="isi-container">
      <button class="isi__expand-button" @click="toggleISI('isi-top')" v-if="!static" aria-label="expand button"><span class="sr-only">{{ buttonText }}</span></button>
      <div class="isi-twocol">
      <div class="isi-top container__wrapper">
        <div class="isi-maxw-container">
          <div class="isi__content">
            <h3 class="firstHeading">Important Safety Information</h3>
            <div class="wariningBox">
              <p>WARNING: HEPATOTOXICITY, CARDIOVASCULAR RISK, and GASTROINTESTINAL RISK</p>
              <ul>
                <li>RISK OF MEDICATION ERRORS: Take care when prescribing, preparing, and administering COMBOGESIC® IV to avoid dosing errors which could result in accidental overdose and death.</li>
                <li>HEPATOTOXICITY: COMBOGESIC® IV contains acetaminophen which has been associated with cases of acute liver failure, at times resulting in liver transplant and death. Most cases of liver injury are associated with doses exceeding 4,000 mg per day and often involve more than one acetaminophen-containing product.</li>
                <li>CARDIOVASCULAR RISK: COMBOGESIC® IV contains ibuprofen, a nonsteroidal anti-inflammatory drug (NSAID). NSAIDs cause an increased risk of serious cardiovascular (CV) thrombotic events, including myocardial infarction and stroke, which can be fatal. This risk may occur early in treatment and may increase with duration of use.</li>
                <li>COMBOGESIC® IV is contraindicated for treatment of peri-operative pain in the setting of coronary artery bypass graft (CABG) surgery.</li>
                <li>GASTROINTESTINAL RISK: NSAIDs cause an increased risk of serious gastrointestinal (GI) adverse events including bleeding, ulceration, and perforation of the stomach or intestines, which can be fatal. These events can occur at any time during use and without warning symptoms. Elderly patients are at greater risk.</li>
              </ul>
            </div>
            <div class="contentBox">
              <h4>CONTRAINDICATIONS</h4>
              <p>COMBOGESIC® IV is contraindicated in:</p>
              <ul class="list">
                <li>Patients with known hypersensitivity to acetaminophen, ibuprofen, other NSAIDs or to any components of this product.</li>
                <li>Patients who have experienced asthma, urticaria, or allergic-type reactions after taking aspirin or other NSAIDs.</li>
                <li>Patients with severe hepatic impairment or severe active liver disease.</li>
              </ul>
            </div>
            <div class="contentBox">
              <h4>WARNINGS & PRECAUTIONS</h4>
              <ul class="list">
                <li><strong>Hepatotoxicity.</strong> COMBOGESIC® IV contains acetaminophen and ibuprofen. Acetaminophen has been associated with cases of acute liver failure; the risk is higher in those with underlying liver disease and in those who ingest alcohol. Use in patients with hepatic impairment is not recommended. Elevations of ALT or AST have been reported in NSAID-treated patients.</li>
                <li><strong>Cardiovascular Thrombotic Events.</strong> To minimize the risks of CV events, use the lowest effective dose for the shortest duration possible.</li>
                <li><strong>Gastrointestinal Bleeding, Ulceration, and Perforation.</strong> NSAIDs may cause serious and sometimes fatal GI adverse events at any time and without warning. Avoid use in patients at higher risk. In concomitant use with low-dose aspirin, monitor patients more closely for evidence of GI bleeding.</li>
                <li><strong>Hypertension.</strong> NSAIDs can lead to onset of new hypertension or worsening of pre-existing hypertension, which may contribute to the increased incidence of CV events.</li>
                <li><strong>Heart Failure and Edema.</strong> Avoid use in patients with severe heart failure unless benefits outweigh risk.</li>
                <li><strong>Renal Toxicity and Hyperkalemia.</strong> Use in patients with renal impairment is not recommended. Long-term administration of NSAIDs has resulted in renal papillary necrosis and other renal injury. Increases in serum potassium concentration, including hyperkalemia, have been reported.</li>
                <li><strong>Hypersensitivity and Anaphylactic Reactions.</strong> Hypersensitivity and anaphylaxis associated with ibuprofen and acetaminophen have been reported, including life-threatening anaphylaxis associated with acetaminophen.</li>
                <li><strong>Exacerbation of Asthma Related to Aspirin Sensitivity.</strong> Patients with asthma may have intolerance to aspirin and other NSAIDs.</li>
                <li><strong>Serious Skin Reactions.</strong> Acetaminophen or NSAIDs may cause serious skin reactions such as exfoliative dermatitis, acute generalized exanthematous pustulosis (AGEP), Stevens-Johnson Syndrome (SJS), and toxic epidermal necrolysis (TEN), which can be fatal and may occur without warning. Discontinue the use of COMBOGESIC® IV at the first appearance of skin rash or any other sign of hypersensitivity.</li>
                <li><strong>Drug Rash with Eosinophilia and Systemic Symptoms (DRESS).</strong> DRESS that may be life-threatening or fatal has been reported in patients taking NSAIDs.</li>
                <li><strong>Fetal Toxicity: Premature Closure of Fetal Ductus Arteriosus.</strong> Avoid use of COMBOGESIC® IV in pregnant women at about 30 weeks gestation and later. COMBOGESIC® IV increases the risk of premature closure of the fetal ductus arteriosus at approximately this gestational age.</li>
                <li><strong>Oligohydramnios/Neonatal Renal Impairment.</strong> Use of COMBOGESIC® IV at about 20 weeks gestation or later in pregnancy may cause fetal renal dysfunction leading to oligohydramnios and, in some cases, neonatal renal impairment.</li>
                <li><strong>Hematologic Toxicity.</strong> Anemia has occurred in NSAID-treated patients and NSAID-treatment may increase the risk of bleeding events.</li>
                <li><strong>Ophthalmological Effects.</strong> Blurred or diminished vision, scotomata, and/or changes in color vision have been reported with oral ibuprofen.</li>
                <li><strong>Aseptic Meningitis.</strong> Aseptic meningitis with fever and coma has been observed in patients on oral ibuprofen.</li>
                <li><strong>Masking of Inflammation and Fever.</strong> Activity of COMBOGESIC® IV in reducing inflammation, and possibly fever, may diminish signs of infections.</li>
                <li><strong>Laboratory Monitoring.</strong> Monitor patients on NSAID treatment with a CBC and a chemistry profile as clinically indicated.</li>
              </ul>
            </div>
            <div class="contentBox">
              <h4>ADVERSE REACTIONS</h4>
              <p>The most common adverse reactions (≥ 3%) were infusion site pain, nausea, constipation, dizziness, infusion site extravasation, vomiting, headache, and somnolence.</p>
            </div>
            <div class="contentBox">
              <h4>DRUG INTERACTIONS</h4>
              <ul class="list">
                <li><strong>Drugs That Interfere with Hemostasis.</strong> Ibuprofen and anticoagulants have a synergistic effect on bleeding. Concomitant use increases the risk of serious bleeding. Concomitant use of drugs that interfere with serotonin reuptake and an NSAID may increase the risk of bleeding.</li>
                <li><strong>ACE Inhibitors, Angiotensin Receptor Blockers, and Beta-Blockers.</strong> NSAIDs may diminish the antihypertensive effect of ACE inhibitors, ARBs, or Beta-Blockers. In the elderly, volume depleted, or those that have renal impairment, concomitant use with ACE inhibitors or ARBs may result in deterioration of renal function, including possible acute renal failure.</li>
                <li><strong>Diuretics.</strong> NSAIDs can reduce the natriuretic effect of loop diuretics and thiazides in some patients.</li>
                <li><strong>Digoxin.</strong> Concomitant use of ibuprofen with digoxin has been reported to increase the serum concentration and prolong the half-life of digoxin.</li>
                <li><strong>Lithium.</strong> NSAIDs produced an elevation of plasma lithium levels and a reduction in renal lithium clearance.</li>
                <li><strong>Methotrexate.</strong> Concomitant use of NSAIDs and methotrexate may increase the risk for methotrexate toxicity.</li>
                <li><strong>Cyclosporine.</strong> Concomitant use of NSAIDS and cyclosporine may increase cyclosporine&rsquo;s nephrotoxicity.</li>
                <li><strong>NSAIDs and Salicylates.</strong> Concomitant use with other NSAIDs or salicylates increases the risk of GI toxicity.</li>
                <li><strong>Pemetrexed.</strong> Concomitant use of NSAIDS and pemetrexed may increase the risk of pemetrexed-associated myelosuppression, renal, and GI toxicity.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="topscrollbar"></div>
      </div>
      <div class="isi-bottom container__wrapper">
        <div class="isi-maxw-container">
          <div class="isi__content">            
            <div class="contentBox">
              <h3>INDICATIONS AND USAGE</h3>
              <p>COMBOGESIC® IV is indicated in adults (over age 18) where an intravenous route of administration is considered clinically necessary for:</p>
              <ul class="list">
                <li>The relief of mild to moderate pain.</li>
                <li>The management of moderate to severe pain as an adjunct to opioid analgesics.</li>
              </ul>
            </div>
            <div class="contentBox">
              <h3>LIMITATIONS OF USE</h3>
              <p>COMBOGESIC® IV is indicated for short-term use of five days or less.</p>
            </div>
            <div class="contentBox contentBox--last">
              <p><strong>For more information, please see the full <a :href="urls.PI.link" target="_blank">{{ urls.PI.text }}</a>, available at <a href="https://www.hikma.com/">www.hikma.com</a>.</strong></p>
              <p><strong>To report an adverse event or product complaint, please contact us at <a href="mailto:us.hikma@primevigilance.com">us.hikma@primevigilance.com</a> or call <span>1-877-845-0689</span> or <span>1-800-962-8364</span>. Adverse events may also be reported to the FDA directly at <span>1-800-FDA-1088</span> or <a href="https://www.fda.gov/medwatch" target="_blank">www.fda.gov/medwatch</a>.</strong></p>
            </div>
          </div>
        </div>
        <div class="bottomscrollbar"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Isi",
  components: {},
  props: {
    static: {
      type: Boolean,
      default: false,
    },
    hideElement: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedIsi: false,
      buttonText: 'expand'
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "mobileMenuState",
      "activeSection",
      "mainMenu",
    ]),
  },
  methods: {
    toggleISI() {
      this.openedIsi = !this.openedIsi;
      document.querySelector("html").style.overflow = this.openedIsi
        ? "hidden"
        : "visible";
        this.buttonText = this.buttonText === 'expand' ? 'collapse' : 'expand'; 
    },
  },
};
</script>
<style lang="scss" src="./Isi.scss"></style>
