<template>
  <section class="AboutProductSection">
    <div class="AboutProductSection__container">
      <div class="AboutProductSection__row">
          <div class="AboutProductSection__left">
            <h3 class="heading" v-html="ProductData.title"></h3>
            <p v-html="ProductData.description"></p>
            <ul>
              <li v-for="(item, index) in ProductData.listing" v-html="item" v-bind:key="index"></li>
            </ul>
            <a class="btn btn--bgOrange" :href="ProductData.Link"><span v-html="ProductData.buttontitle"></span><img img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg"
              alt="Right arrow" /></a>
          </div>
          <div class="AboutProductSection__right">
            <div class="productImage">
              <img :src="ProductData.image" :alt="ProductData.title"/>
              <span v-html="ProductData.imagetitle"></span>
            </div>
            <p v-html="ProductData.imageDescription"></p>
          </div>
      </div>
      <ul class="footnote">
        <li><strong>Reference</strong>: <strong>1.</strong>&nbsp;COMBOGESIC® IV (acetaminophen 1000 mg and ibuprofen 300 mg) [package insert]. Hikma Pharmaceuticals USA Inc.</li>
      </ul>
      </div> 
  </section>
</template> 

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "AboutProductSection",
  mixins: [ClickAnalytics],
  props: {
    ProductData: Object,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./AboutProductSection.scss" scoped></style>