<template>
  <section class="StudyDesignSection">
    <h3 class="heading" v-html="StudyDesignData.Title"></h3>
    <ul class="list">
      <li v-for="(item, index) in StudyDesignData.listing" v-html="item" v-bind:key="index"></li>
    </ul>
    <div class="viewStudy" v-for="(item, index) in StudyDesignData.calloutsection" v-bind:key="index">
      <div class="viewStudy__left">
        <h4 v-html="item.title"></h4>
        <p v-html="item.description"></p>
      </div>
      <div class="viewStudy__right">
        <a class="btn" :href="item.link" target="_blank"><span>View Study</span><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" /></a>
      </div>
    </div>
    <div class="callOutBox">
      <div class="callOutBox__icon"><img src="@/Assets/Img/Icons/icon-pain-model-badge.svg" alt="" /></div>
      <p v-html="StudyDesignData.boxdata"></p>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "StudyDesignSection",
  mixins: [ClickAnalytics],
  props: {
    StudyDesignData: Object,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./StudyDesignSection.scss" scoped></style>