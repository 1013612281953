<template src="./SiteMap.html"></template>

<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
export default {
  name: "SiteMap",
  components: {
    InnerHeroSection,
  },
  data() {
    return {
      HeroBoxData: {
          title: "Site Map",
      },

    }
  }
};
</script>

<style lang="scss" src="./SiteMap.scss" scoped></style>