<template>
  <div class="tableContainer" :class="TableData.class">
    <h3 v-html="TableData.tableTitle" v-if="TableData.tableTitle.length > 0"></h3>
    <div class="tableContainer__inner hideTablesMobile">
      <table>
        <thead>
          <tr v-for="(tr, i) in TableData.thead" :key="i" :class="tr.className || false">
            <th v-for="(th, j) in tr.columns" :key="j" :colspan="th.colspan || false" :rowspan="th.rowspan || false"
              :class="th.className || false" v-html="th.text">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tr, i) in TableData.tbody" :key="i" :class="tr.className || false">
            <td v-for="(td, j) in tr.columns" :key="j" :colspan="td.colspan || false" :rowspan="td.rowspan || false"
              :class="td.className || false" v-html="td.text">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tableContainer__inner hideDesktop" v-if="$route.name == 'Cookie Policy'">
      <div class="tableHeader">
        <h4 v-html="TableData.thead[0].columns[0].text"></h4>
      </div>
      <ul v-for="(tr, i) in TableData.tbody" :key="i" class="tableListing" :class="tr.className || false">
        <li v-for="(td, j) in tr.columns" :key="j" :class="td.className || false">
          <strong v-if="j == 1">Provider:</strong>
          <strong v-if="j == 2">Purpose:</strong>
          <strong v-if="j == 3">Duration:</strong>
          <span v-html="td.text"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "Table",
  computed: {
    routes() {
      return this.$router.options.routes;
    },
  },
  props: {
    TableData: Object,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" src="./Table.scss" scoped></style>