<template>
  <section class="PainModelSection">
    <div class="PainModelSection__content">
      <h3 class="subHeading" v-html="PainModelData.Title"></h3>
      <div class="painModal">
        <div class="painModal__content">
          <div class="painModal__title" v-html="PainModelData.Subtitle"></div>
          <div class="hideMobile">
            <div class="painModal__boxes">
              <div class="painModal__box" v-for="(item, index) in PainModelData.Boxsection" v-bind:key="index" :class="'painModal__box'+index">
                <div class="painModal__boxUpper">
                  <div class="painModal__boxImage"><img :src="item.image" :alt="item.title" /></div>
                  <p v-html="item.title"></p>
                </div>
                <div class="painModal__learnMore">
                  <span><a :href="item.link">Learn More</a><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" /></span>
                </div>
              </div>
            </div>
          </div>
          <div class="hideDesktop">
            <div class="painModal__boxes">
              <VueSlickCarousel v-bind="settings">
                <div class="slide" v-for="(item, index) in PainModelData.Boxsection" v-bind:key="index">
                  <div class="painModal__box" :class="'painModal__box'+index">
                    <div class="painModal__boxUpper">
                      <div class="painModal__boxImage"><img :src="item.image" :alt="item.title" /></div>
                      <p v-html="item.title"></p>
                    </div>
                    <div class="painModal__learnMore">
                      <span><a :href="item.link">Learn More</a><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" /></span>
                    </div>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
          </div>
          <ul class="footnote">
            <li v-for="(item, index) in PainModelData.footnote" v-html="item" v-bind:key="index"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  name: "PainModelSection",
  mixins: [ClickAnalytics],
  props: {
    PainModelData: Object,
  },
  components: {
    VueSlickCarousel,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      carousels: [],
      settings: {
        dots: true,
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        sliderPageIndex: 0,
        initialSlide: 0,
      },
    }
  }
};
</script>

<style lang="scss" src="./PainModelSection.scss" scoped></style>