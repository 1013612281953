<template>
  <section class="InnerHeroSection">    
      <h2 v-html="HeroBoxData.title"></h2>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "InnerHeroSection",
  mixins: [ClickAnalytics],
  props: {
    HeroBoxData: Object,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./InnerHeroSection.scss" scoped></style>