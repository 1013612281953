<template src="./Restricted.html"></template>

<script>
export default {
  name: "Restricted",
  components: {
  },
  data() {
    return {
    }
  }
};
</script>

<style lang="scss" src="./Restricted.scss" scoped></style>