<template>
  <section class="HeroeBoxSection">
    <div class="HeroeBoxSection__eyeBrow">
      <span v-html="HeroBoxData.subtitle"></span>
    </div>
    <div class="HeroeBoxSection__headings">
      <h1 v-html="HeroBoxData.title"></h1>
      <h2 v-html="HeroBoxData.titleSmall"></h2>
      <p v-html="HeroBoxData.description"></p>
    </div>

    <div class="hideMobile">
      <div class="HeroeBoxSection__boxes">
        <div class="HeroeBoxSection__box" v-for="(item, index) in HeroBoxData.colData" v-bind:key="index"
          :class="'HeroeBoxSection__box' + index">
          <div class="HeroeBoxSection__image">
            <img :src="item.icon" :alt="item.title" />
          </div>
          <div class="HeroeBoxSection__info">
            <h3 v-html="item.title"></h3>
            <p v-html="item.description"></p>
            <a :href="item.link">Learn More<img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="hideDesktop">
      <div class="HeroeBoxSection__boxes">
        <VueSlickCarousel v-bind="settings">
          <div class="slide" v-for="(item, index) in HeroBoxData.colData" v-bind:key="index">
            <div class="HeroeBoxSection__box" :class="'HeroeBoxSection__box' + index">
              <div class="HeroeBoxSection__image">
                <img :src="item.icon" :alt="item.title" />
              </div>
              <div class="HeroeBoxSection__info">
                <h3 v-html="item.title"></h3>
                <p v-html="item.description"></p>
                <a :href="item.link">Learn More<img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" /></a>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>

    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  name: "HeroeBoxSection",
  mixins: [ClickAnalytics],
  props: {
    HeroBoxData: Object,
  },
  components: {
    VueSlickCarousel,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      carousels: [],
      settings: {
        dots: true,
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        sliderPageIndex: 0,
        initialSlide: 0,
      },
    }
  }
};
</script>

<style lang="scss" src="./HeroeBoxSection.scss" scoped></style>