const ClickAnalytics = {
    data() {
      return {
        triggers: [],
      };
    },
    methods: {
      sectionClickDataLayer(pagename, eventname, subsection){
        if (window.dataLayer) {
          window.dataLayer.push({
              event: eventname,
              "page-name": pagename,
              "subsection": subsection,
          });
        }
      },
      buttonDataLayer(pagename, eventname, buttonname){
        if (window.dataLayer) {
          window.dataLayer.push({
              event: eventname,
              "page-name": pagename,
              "button-name": buttonname,
          });
        }
      },
    },
    destroyed() {
      this.triggers.forEach((item) => {
        item.kill();
      });
      this.triggers = [];
    },
  };
  
  export default ClickAnalytics;
  