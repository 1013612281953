<template src="./PainManagement.html"></template>

<script>
import { mapState } from "vuex";
import ScrollDepthAnalytics from "@/mixins/ScrollDepthAnalytics";
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import AcutePainManagementSection from "@/Components/Blocks/AcutePainManagementSection/AcutePainManagementSection";
import MultimodalAnalgesiaSection from "@/Components/Blocks/MultimodalAnalgesiaSection/MultimodalAnalgesiaSection";
import PowerofSynergySection from "@/Components/Blocks/PowerofSynergySection/PowerofSynergySection";
import HomePageNavSection from "@/Components/Blocks/HomePageNavSection/HomePageNavSection";

export default {
  name: "PainManagement",
  components: {
    InnerHeroSection,
    AcutePainManagementSection,
    MultimodalAnalgesiaSection,
    PowerofSynergySection,
    HomePageNavSection,
  },
  mixins: [ScrollDepthAnalytics],
  data() {
    return {
      HeroBoxData: {
          title:"PAIN MANAGEMENT",
      },
      AcutePainManagementData:{
        Title: "Acute Pain Management in Adults",
        Subtitle:"Acute pain affects millions of patients annually and is the most common presenting complaint to emergency departments in the US<sup>1</sup>",
        Description:"Among nonsurgical causes, musculoskeletal injuries have accounted for over 65 million healthcare encounters, while more than 80% of surgical patients in the US experience acute postoperative pain that is not adequately managed.<sup>2,3</sup>",
        Boxsection:[
          // For futur reference
          // {
          //   title:"Understanding How Pain Can Manifest",
          //   description:"Inadequate pain control is commonly experienced by pain both inside and outside the hospital setting and can be attributed to a variety of acute pain conditions.<sup>5</sup>",
          //   listingtitle:"Conditions Include<sup>5</sup>:",
          //   listing:["Musculoskeletal pain","Migraines (episodic pain)","Dental pain","Sickle Cell crisis (episodic pain)","Kidney stones (episodic pain)","Other episodic pain","Postoperative pain after discharge from surgical facility"]
          // },
           {
            title:"The Impact of Ineffective Pain Management",
            description:"Despite the heightened attention and administration of analgesic medication in recent years, a significant number of patients still report inadequate postoperative pain relief.<sup>3,4</sup> <span class='newPara'>Ineffective pain management can lead to a marked decrease in desirable clinical and psychological outcomes and patients' overall quality of life.<sup>5</sup></span>",
            listingtitle:"Consequences Include<sup>3,4</sup>:",
            listing:["Decreased lung function","Sleep disturbances","Infection","Functional impairment","Myocardial infarction","Increased morbidity and mortality","Delayed recovery time","Increased hospital readmissions","Prolonged opioid use","Higher health-care costs"]
          },
        ],
        Boxtitle:"Evidence suggests that less than half of patients who undergo surgery report adequate postoperative pain relief<sup>6</sup>",
        Boximage: require("@/Assets/Img/Icons/icon-50-percent.svg"),
        Quotedata: "The most effective strategy for improving pain control and decreasing adverse medication events in acute care settings is multimodal analgesia<sup>7</sup>",
        QuateCompany:"",
      },
      MultimodalAnalgesiaData:{
        Title: "Multimodal Analgesia",
        Subtitle: "Evidence-based recommendations emphasize the importance of a multimodal approach to pain management using multiple interventions with different mechanisms of action that may offer additive or synergistic effects to optimize pain relief.<sup>6</sup>",
        Description: "",
        listingtitle: "Key Medical Associations Cited:",
        listing: ["American Pain Society (APS)","American Society of Regional Anesthesia and Pain Medicine (ASRA)","American Society of Anesthesiologists (ASA)"],
        Boxtitle: "A novel strategy involves utilizing a combination product of non-opioid analgesics to <u class='fontWeightBold'>harness</u> the benefits of a multimodal approach while minimizing the risk of side effects and reliance on opioids<sup>6</sup>",
      },
      PowerofSynergyData:{
        Title: "Harness the Power of Synergy<sup>&trade;</sup>",
        Description: "COMBOGESIC® IV is a fixed dose combination (FDC) of acetaminophen and ibuprofen that optimizes its synergistic effects through its different mechanisms of action<sup>8,9</sup>",
        Boxsection:[
          {
            title: "Advanced Approach to Multimodal Analgesia",
            image: require("@/Assets/Img/Icons/icon-synergy-1.svg"),
            description: "",
            listing: ["COMBOGESIC® IV contains active drug substances with different mechanisms of action that harness additive or synergistic effects to provide <span class='highlight'>greater pain relief</span> compared with individual components used as single-modality interventions<sup>6,8,9</sup>"]
          },
           {
            title: "Unique Formulation",
            image: require("@/Assets/Img/Icons/icon-synergy-2.svg"),
            description: "COMBOGESIC® IV is the only IV analgesic therapy formulated with 1,000 mg of acetaminophen and 300 mg of ibuprofen, utilizing the synergistic effect of both medicines for <span class='highlight'>optimal pain relief</span><sup>8</sup>",
            listing: []
          },
           {
            title: "Ready-to-Use Administration",
            description: "",
            image: require("@/Assets/Img/Icons/icon-synergy-3.svg"),
            listing: ["Readily available solution with <span class='highlight'>no mixing required</span> for administration<sup>8</sup>","Administer as a 15-minute IV infusion, every 6 hours as needed, not to exceed the maximum total daily dose of 4,000 mg acetaminophen and 1,200 mg of ibuprofen in 24 hours<sup>8</sup>"]
          },
        ],
        Boxtitle:"Harness the synergistic effects of COMBOGESIC® IV",
        BoxLink: "/#/analgesic-efficacy",
        Quotedata1: "Harness the <span class='purple'>power of synergy<sup>&trade;</sup></span>",
        Quotedata2: "acetaminophen + ibuprofen = COMBOGESIC<sup>&reg;</sup> IV",
        Quoteimage: require("@/Assets/Img/ProductShots/vial-box-combo-lg.png"),
      },
      HomePageNavData:[
         {
          title: "QUESTIONS ABOUT COMBOGESIC® IV?",
          description: "Find answers to frequently asked questions and more.",
          link: "/#/faqs",
          linkText: "View FAQs",
          icon : require("@/Assets/Img/Icons/icon-questions.svg"),
        },
      ]
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "animationsData",
      "controllerExist",
      "mainMenu",
      "activeSection",
    ]),
    isIE() {
      return this.userAgent.browser.name === "ie";
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  methods: {
    clickAnchor(anchor, offset) {
      if (typeof offset !== "undefined") {
        this.$scrollTo(anchor, 750, {
          container: "body",
          easing: "ease-in",
          offset: offset,
        });
      } else {
        this.$scrollTo(anchor, 750, this.options);
      }
    },
  },
  mounted() {
    this.initScrollDepthAnalytics();
  },
  watch: {
    activeSection(newVal) {},
  },
};
</script>

<style lang="scss" src="./PainManagement.scss" scoped></style>