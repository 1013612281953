import { render, staticRenderFns } from "./Restricted.html?vue&type=template&id=5b65c637&scoped=true&external"
import script from "./Restricted.vue?vue&type=script&lang=js"
export * from "./Restricted.vue?vue&type=script&lang=js"
import style0 from "./Restricted.scss?vue&type=style&index=0&id=5b65c637&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b65c637",
  null
  
)

export default component.exports