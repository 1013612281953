import Vue from 'vue';
import Router from 'vue-router';
import Home from './Views/Home.vue';
import PainManagement from './Views/PainManagement/PainManagement.vue';
import AnalgesicEfficacy from './Views/AnalgesicEfficacy/AnalgesicEfficacy.vue';
import PatientCases from './Views/PatientCases/PatientCases.vue';
import Resources from './Views/Resources/Resources.vue';
import FAQs from './Views/FAQs/FAQs.vue';
import ContactUs from './Views/ContactUs/ContactUs.vue';
import Patents from './Views/Patents/Patents.vue';
import AccessibilityStatement from './Views/AccessibilityStatement/AccessibilityStatement.vue';
import CookiePolicy from './Views/CookiePolicy/CookiePolicy.vue';
import SiteMap from './Views/SiteMap/SiteMap.vue';
import Restricted from './Views/Restricted/Restricted.vue';
import NotFound from './Views/Opps/Opps.vue';

Vue.use(Router);

const router = new Router({
	mode: 'hash',
	base: process.env.DEVELOPMENT_SUBFOLDER,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: {
				title: 'COMBOGESIC® IV',
			},
		},
		{
			path: '/pain-management',
			name: 'Pain Management',
			component: PainManagement,
			meta: {
				title: 'Pain Management | COMBOGESIC® IV',
			},
			submenu:[
				{
					path: '/pain-management/#acute-pain-management',
					name: 'Acute Pain Management in Adults',
				},
				{
					path: '/pain-management/#multimodal-analgesia',
					name: 'Multimodal Analgesia',
				},
				{
					path: '/pain-management/#power-of-synergy',
					name: 'Harness the Power of Synergy&trade;',
				}
			]
		},
		{
			path: '/analgesic-efficacy',
			name: 'Analgesic Efficacy',
			component: AnalgesicEfficacy,
			meta: {
				title: 'Analgesic Efficacy | COMBOGESIC® IV',
			},
			submenu:[
				{
					path: '/analgesic-efficacy/#pain-model',
					name: 'COMBOGESIC® IV Pain Model©',
				},
				{
					path: '/analgesic-efficacy/#shorter-onset-to-analgesia',
					name: 'Shorter Onset to Analgesia',
				},
				{
					path: '/analgesic-efficacy/#superior-efficacy',
					name: 'Superior Efficacy',
				},
				{
					path: '/analgesic-efficacy/#sustained-results',
					name: 'Sustained Results',
				}
			]
		},
		// Commnet route for future reference
		// {
		// 	path: '/patient-cases',
		// 	name: 'Patient Cases',
		// 	component: PatientCases,
		// 	meta: {
		// 		id: 0
		// 	}
		// },
		{
			path: '/resources',
			name: 'Resources',
			component: Resources,
			meta: {
				title: 'Resources | COMBOGESIC® IV',
			},
			submenu:[
				{
					path: '/resources/#education-tools',
					name: 'Education Tools',
				},
				{
					path: '/resources/#product-ordering',
					name: 'Product Ordering',
				},
				{
					path: '/contact-us',
					name: 'Hikma Support',
				}
			]
		},
		{
			path: '/faqs',
			name: 'FAQs',
			component: FAQs,
			meta: {
				title: 'FAQs | COMBOGESIC® IV',
			},
		},
		{
			path: '/contact-us',
			name: 'Contact Us',
			component: ContactUs,
			meta: {
				title: 'Contact Us | COMBOGESIC® IV',
			},
		},
		{
			path: '/patents',
			name: 'Patents',
			component: Patents,
		},
		{
			path: '/accessibility-statement',
			name: 'Accessibility Statement',
			component: AccessibilityStatement,
		},
		{
			path: '/cookie-policy',
			name: 'Cookie Policy',
			component: CookiePolicy,
		},
		{
			path: '/site-map',
			name: 'Site Map',
			component: SiteMap,
		},
		{
			path: '/restricted',
			name: 'Restricted',
			component: Restricted,
		},
		{
		  path: '*',
		  name: 'NotFound',
		  component: NotFound,
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return new Promise((resolve) => {
				setTimeout(() => {
				  resolve({
					selector: to.hash,
					behavior: 'smooth',
					offset: { x: 0, y: window.innerWidth > 768 ? 150 : 100 },
				  });
				}, 300); // Adjust this delay based on your component load times
			  });
		} else {
		  return { x: 0, y: 0 };
		}
	  }
});

router.afterEach((to) => {
	document.title = to.meta.title || 'COMBOGESIC® IV';
  });

// Move beforeEach outside of the Router instance creation
router.beforeEach((to, from, next) => {
	if (to.name === 'NotFound') {
		document.body.classList.add('removeISI');
	}else if (to.name === 'Contact Us' || to.name === 'Cookie Policy' || to.name === 'Accessibility Statement' || to.name === 'Patents') {
		document.body.classList.add('removeISI');
	} else {
		document.body.classList.remove('removeISI');
	}
	next();
});

export default router;