<template>
  <section>
    <ReferenceSection :ReferenceList="PainReferenceList" v-if="$route.name == 'Pain Management'" />  
    <ReferenceSection :ReferenceList="AnalgesicReferenceList" v-if="$route.name == 'Analgesic Efficacy'" />  
    <ReferenceSection :ReferenceList="patientCasesReferenceList" v-if="$route.name == 'Patient Cases'" />  
    <ReferenceSection :ReferenceList="faqReferenceList" v-if="$route.name == 'FAQs'" />  
    <div class="brandFooter">
      <div class="brandFooter__inner">
        <div class="upper"><img src="@/Assets/Img/Logos/footer-logo.svg" alt="Combogesic logo" /></div>
        <div class="lower">
          <div class="lower__firstRow">
            <!-- for future refrence
              div class="stayInformed">
              <h2>STAY INFORMED</h2>
              <p>Subscribe for valuable product updates and information on adult <span class="nowrap">pain management.</span></p>
              <div class="emailbox">
                <div class="input"><input type="text" name="emailaddress" aria-label="email address" placeholder="Email Address" /></div>
                <div class="button"><button class="button" type="submit" aria-label="submit">Sign Up<img src="@/Assets/Img/Icons/icon-email.svg" alt="Email Icon" /></button></div>
              </div>
              <div class="agree"><input type="checkbox" name="agreepolicy" id="agreepolicy" aria-label="agree policy" /> <label for="agreepolicy">By checking this box, you are agreeing to our <a href="https://www.hikma.com/useful-links/privacy-policy/" target="_blank">Privacy Policy</a></label></div>
            </div-->
            <div class="navigation">
              <div class="navigation__links">
                <div class="navigation__linksBox">
                  <h2>Navigation</h2>
                  <ul>
                    <li v-for="(route, index) in $router.options.routes" :key="route.name" v-if="route.name != 'home' && index <= 4"
                      :class="{'active': route.name == $route.name,'submenu' : route.submenu}">
                      <router-link :to="route.path" class="routelink">
                        <span v-html="route.name" class="routelink"></span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="navigation__linksBox">
                  <h2>Site Tools</h2>
                  <ul>
                    <li><router-link to="/contact-us">Contact Us</router-link></li>
                    <li><router-link to="/site-map">Site Map</router-link></li>
                  </ul>
                </div>
                <div class="navigation__linksBox">
                  <h2>Legal</h2>
                  <ul>
                    <li><router-link to="/patents">Patents</router-link></li>
                    <li><a href="https://www.hikma.com/useful-links/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.hikma.com/useful-links/terms-of-use/" target="_blank">Terms of Use</a></li>
                    <li><router-link to="/cookie-policy">Cookie Policy</router-link></li>
                    <li><router-link to="/accessibility-statement">Accessibility Statement</router-link></li>
                    <li><a href="https://www.hikma.com/useful-links/disclaimer/" target="_blank">Disclaimer</a></li>
                  </ul>
                </div>
              </div>
              <div class="navigation__logoBox">
                <div class="hikmaLogo"><img src="@/Assets/Img/Logos/client-logo.svg" alt="Hikma logo" /></div>
                <p>Learn more about who we are, what we do, and our commitments to sustainable business.</p>
                <div class="hikmaLink"><a href="https://www.hikma.com/" target="_blank">View Website</a><img src="@/Assets/Img/Icons/icon-view.svg" alt="View Website" /></div>
              </div>
            </div>
          </div>
          <div class="lower__secondRow">
            <div class="sponsores">
              <p>COMBOGESIC® is a registered trademark of AFT Pharmaceuticals Limited.</p>
              <p>COMBOGESIC® IV Pain Model<sup>©</sup> is a copyright of Hikma Pharmaceuticals USA Inc.</p>
              <p>Harness the Power of Synergy<sup>TM</sup> is a trademark of Hikma Pharmaceuticals USA Inc.</p>
            </div>
            <div class="copyright">
              <p>©2024 Hikma Pharmaceuticals USA Inc. All rights reserved.</p>
              <p>HK-2604-v1  09/24</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ReferenceSection from "@/Components/Blocks/Reference/ReferenceSection";
export default {
  name: "BrandFooter",
  components: {
    ReferenceSection,
  },
  data() {
    return {
      PainReferenceList: [
        "Fine M. Quantifying the impact of NSAID-associated adverse events. <i>Am J Manag Care</i>. 2013;19(suppl 14):S267-S72.",
        "Busse JW, Sadeghirad B, Oparin Y, et al. Management of acute pain from non&ndash;low back, musculoskeletal injuries. <i>Ann Intern Med.</i> 2020;173(9):730-738. doi:10.7326/M19-3601",
        
        "Gan TJ. Poorly controlled postoperative pain: prevalence, consequences, and prevention. <i>J Pain Res</i>. 2017;10:2287-2298.",
        "Walton LL, Duff E, Arora RC, McMillan DE. Surgery patients’ perspectives of their role in postoperative pain: a scoping review. <i>Int J Nurs Stud Adv</i>. 2023;5:100124.",
        "Glowacki D. Effective pain management and improvements in patients&rsquo; outcomes and satisfaction. <i>Crit Care Nurse.</i> 2015;35(3):33-41. doi:10.4037/ccn2015440",
        "Chou R, Gordon DB, Leon-Casasola OA de, et al. Management of postoperative pain: a clinical practice guideline from the American Pain Society, the American Society of Regional Anesthesia and Pain Medicine, and the American Society of Anesthesiologists&rsquo; Committee on Regional Anesthesia, Executive Committee, and Administrative Council. <i>J Pain.</i> 2016;17(2):131-157. doi:10.1016/j.jpain.2015.12.008",
        "Hyland SJ, Wetshtein AM, Grable SJ, Jackson MP. Acute pain management pearls: a focused review for the hospital clinician. <i>Healthcare (Basel)</i>. 2022;11(1):34.",
        "COMBOGESIC® IV (acetaminophen 1000 mg and ibuprofen 300 mg) [package insert]. Hikma Pharmaceuticals USA Inc.",
        "Daniels SE, Playne R, Stanescu I, et al. Efficacy and safety of an intravenous acetaminophen/ibuprofen fixed-dose combination after bunionectomy: a randomized, double-blind, factorial, placebo-controlled trial. <i>Clin Ther.</i> 2019;41(10):1982-1995.e8. doi:10.1016/j.clinthera.2019.07.008",
      ],
      AnalgesicReferenceList: [
        "Daniels SE, Playne R, Stanescu I, et al. Efficacy and safety of an intravenous acetaminophen/ibuprofen fixed-dose combination after bunionectomy: a randomized, double-blind, factorial, placebo-controlled trial. <i>Clinical Therapeutics.</i> 2019;41(10).",
        "Daniels SE, Atkinson HC, Stanescu I, et al. Analgesic efficacy of an acetaminophen/ibuprofen fixed-dose combination in moderate to severe postoperative dental pain: a randomized, double-blind, parallel-group, placebo-controlled trial. <i>Clinical Therapeutics</i>. 2018;40(10).",
        "COMBOGESIC® IV (acetaminophen 1000 mg and ibuprofen 300 mg) [package insert]. Hikma Pharmaceuticals USA Inc.",
        "Data on File. AFT Pharmaceuticals. 2023."
      ],
      patientCasesReferenceList: [
        "COMBOGESIC® IV (acetaminophen 1000 mg and ibuprofen 300 mg) [package insert]. Hikma Pharmaceuticals USA Inc."
      ],
      faqReferenceList: [
        "COMBOGESIC® IV (acetaminophen 1000 mg and ibuprofen 300 mg) [package insert]. Hikma Pharmaceuticals USA Inc."
      ]
    }
  }
};
</script>

<style lang="scss" src="./BrandFooter.scss"></style>