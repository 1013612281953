import router from "@/router";

/**
 * Quiz module
 * Contains quiz items in state
 * Have functionality for polls api
 * To have localStorage linked with state, setup "vuex-persist" npm package in index.js of Vuex
 */

import axios from "axios";

/**
 * Event name for GTM analytics
 * @const {string}
 */
const analEventName = "poll";

/**
 * Project slug for api
 * @const {string}
 */
const projectSlug = "merck-keytruda_patient_journey";

/**
 * Patient slug for api
 * @const {string}
 */
const pageJh = "JH";
const pageLE = "LE";
const pagesFullTitles = [
    'patient-john',
    'patient-lee',
]

/**
 * Push analytics data to GTM layerData
 * @param {object} analData analytics data object (contains quizId and analValue)
 */
const pushAnalData = (analData) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: analData.event,
      'page-name': analData.pageName,
      'question #': `question-${analData.idOnPage}`,
      'question response': analData.response,
    });
    // console.log(
    //   "%c Pushed new data to dataLayer:",
    //   "background: #222; color: #bada55",
    //   window.dataLayer
    // );
  }
};

const quiz = {
  state: () => ({
    quizItems: [
      {
        title: "What factors matter to you? Rank the following factors in order of priority when choosing",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageJh}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 1,
        pageName: pagesFullTitles[0],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Overall survival in clinical trial",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 1,
            key: "b",
            text: "PD-L1 expression (CPS)",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 2,
            key: "c",
            text: "Results of diagnostic testing",
            anal_value: "C",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 3,
            key: "d",
            text: "ECOG PS",
            anal_value: "D",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 4,
            key: "e",
            text: "Prior clinical experience with treatment option",
            anal_value: "E",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 5,
            key: "f",
            text: "Recommendation from my peers",
            anal_value: "F",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "After viewing the clinical data, would you change your rankings of factors in choosing a first-line treatment for your patients with advanced esophageal or GEJ cancer?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageJh}/1/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 2,
        pageName: pagesFullTitles[0],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Yes",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 10,
            isNotScroll: false,
          },
          {
            order: 1,
            key: "b",
            text: "No",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "What is your current practice in testing patients with advanced esophageal cancer for PD-L1 expression?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageJh}/2/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 3,
        pageName: pagesFullTitles[0],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "I test all my patients for PD-L1 expression (CPS) during assessment (100%)",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 10,
            isNotScroll: false,
          },
          {
            order: 1,
            key: "b",
            text: "I test most of my patients for PD-L1 expression (CPS) during assessment (75%)",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 2,
            key: "c",
            text: "I test some of my patients for PD-L1 expression (CPS) during assessment",
            anal_value: "C",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 3,
            key: "d",
            text: "I generally do not do PD-L1 expression (CPS) testing",
            anal_value: "D",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 4,
            key: "e",
            text: "I start treatment before CPS test results are available",
            anal_value: "E",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 5,
            key: "f",
            text: "I treat regardless of CPS results",
            anal_value: "F",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "After viewing John’s treatment and follow-up, has your view of testing for PD-L1 expression at assessment changed?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageJh}/3/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 4,
        pageName: pagesFullTitles[1],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Has changed",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 10,
            isNotScroll: false,
          },
          {
            order: 1,
            key: "b",
            text: "Has not changed",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "What factors matter to you? Rank the following factors in order of priority when choosing:",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageLE}/4/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 5,
        pageName: pagesFullTitles[1],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Overall survival in clinical trial",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 1,
            key: "b",
            text: "PD-L1 expression (CPS)",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 2,
            key: "c",
            text: "Results of diagnostic testing",
            anal_value: "C",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 3,
            key: "d",
            text: "ECOG PS",
            anal_value: "D",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 4,
            key: "e",
            text: "Prior clinical experience with treatment option",
            anal_value: "E",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
          {
            order: 5,
            key: "f",
            text: "Recommendation from my peers",
            anal_value: "F",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "After viewing the clinical data, would you change your rankings of factors in choosing a first-line treatment for your patients with advanced esophageal or GEJ cancer?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageLE}/5/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 6,
        pageName: pagesFullTitles[1],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Yes",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 10,
            isNotScroll: false,
          },
          {
            order: 1,
            key: "b",
            text: "No",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "What is your current practice in testing patients with advanced esophageal cancer for PD-L1 expression?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageLE}/6/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 7,
        pageName: pagesFullTitles[1],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "I test all my patients for PD-L1 expression (CPS) during assessment (100%)",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 10,
            isNotScroll: false,
          },
          {
            order: 1,
            key: "b",
            text: "I test most of my patients for PD-L1 expression (CPS) during assessment (75%)",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 2,
            key: "c",
            text: "I test some of my patients for PD-L1 expression (CPS) during assessment (25%-50%)",
            anal_value: "C",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 3,
            key: "d",
            text: "I generally do not do PD-L1 expression (CPS) testing",
            anal_value: "D",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 4,
            key: "e",
            text: "I start treatment before CPS test results are available",
            anal_value: "E",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
          {
            order: 5,
            key: "f",
            text: "I treat regardless of CPS results",
            anal_value: "F",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
      {
        title: "After viewing Lee’s treatment and follow-up, has your view of testing for PD-L1 expression at assessment changed?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/v2/${projectSlug}/${pageLE}/7/`,
        show_answer_stats: true,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: false,
        idOnPage: 8,
        pageName: pagesFullTitles[1],
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Has changed",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 10,
            isNotScroll: false,
          },
          {
            order: 1,
            key: "b",
            text: "Has not changed",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 20,
            isNotScroll: false
          },
        ],
        isSubmitted: false,
      },
    ],
  }),
  mutations: {
    SUBMIT_ANSWER(state, payload) {
      state.quizItems[payload.quizId].isSubmitted = true;
      state.quizItems[payload.quizId].buttons[
        payload.buttonId
      ].isChecked = !state.quizItems[payload.quizId].buttons[payload.buttonId]
        .isChecked;
    },

    TOGGLE_CHECKBOX(state, payload) {
      state.quizItems[payload.quizId].buttons[
        payload.checkboxId
      ].isChecked = !state.quizItems[payload.quizId].buttons[payload.checkboxId]
        .isChecked;
    },

    SUBMIT_CHECKBOX_QUIZ(state, payload) {
      state.quizItems[payload.quizId].isSubmitted = true;
    },

    SET_QUIZ_STATS(state, payload) {
      /**
       * Confusing things here:
       * Parsing api request obj into quiz buttons
       * Calculating summary votes in absolute value
       */
      let sum = 0;
      state.quizItems[payload.quizId].buttons.forEach((quizItem) => {
        quizItem.stats = payload.stats[0].home[payload.quizId][quizItem.key];
        sum += parseInt(payload.stats[0].home[payload.quizId][quizItem.key]);
      });
      /**
       * Converting absolute value votes => to percentage
       */
      state.quizItems[payload.quizId].buttons.forEach((quizItem) => {
        quizItem.stats = Math.round(
          (payload.stats[0].home[payload.quizId][quizItem.key] / sum) * 100
        );
      });
    },

    SET_LOADING_STATUS(state, payload) {
      state.quizItems[payload.quizId].isLoading = payload.isLoading;
    },

    /**
     * Set initial state (un-submitted) to quizzes with "isFinal" flag
     * @param state
     * @param payload
     * @constructor
     */
    RESET_FINAL_QUIZZES(state, payload) {
      const finalQuizzes = state.quizItems.filter((item) => item.isFinal);
      finalQuizzes.forEach((item) => {
        item.isSubmitted = false;
        item.buttons.forEach((button) => {
          button.isChecked = false;
        });
      });
    },

    PUSH_ROUTER(state, payload) {
      const routerLink =
        state.quizItems[payload.quizId].buttons[payload.buttonId]
          .routerLinkToRouteURL;
      if (routerLink) {
        setTimeout(() => {
          router.push({ path: routerLink });
        }, 300);
      }
    },

    CHANGE_QUIZ_ITEMS_ORDER(state, payload) {
      const moveItem = (arr, itemIndex, targetIndex) => {
        let itemRemoved = arr.splice(itemIndex, 1);
        arr.splice(targetIndex, 0, itemRemoved[0]);
        return arr;
      }

      const buttonsArray = state.quizItems[payload.quizId].buttons;
      let newIndex = payload.direction ? payload.buttonId + 1 : payload.buttonId - 1;
      if(payload.buttonId === 0 && !payload.direction) {
        newIndex = buttonsArray.length - 1;
      }
      if(payload.buttonId === (buttonsArray.length - 1) && payload.direction) {
        newIndex = 0
      }

      state.quizItems[payload.quizId].buttons = moveItem(buttonsArray, payload.buttonId, newIndex);
    },

    SET_NEW_BUTTONS(state, payload) {
      state.quizItems[payload.quizId].buttons = payload.buttons;
    }
  },
  actions: {
    submitAnswer(context, payload) {
      context.commit("SUBMIT_ANSWER", payload);
      pushAnalData(payload.dataLayer);
      context.commit("PUSH_ROUTER", payload);
    },

    toggleCheckbox(context, payload) {
      context.commit("TOGGLE_CHECKBOX", payload);
    },

    submitCheckboxQuiz(context, payload) {
      context.commit("SUBMIT_CHECKBOX_QUIZ", payload);
      pushAnalData(payload.dataLayer);
    },

    resetFinalQuizzes(context) {
      context.commit("RESET_FINAL_QUIZZES");
    },

    getQuizStats({ commit, state }, payload) {
      const submittedVariant =
        state.quizItems[payload.quizId].buttons[payload.buttonId].key;
      payload.isLoading = true;
      commit("SET_LOADING_STATUS", payload);
      axios
        .post(state.quizItems[payload.quizId].api_url, {
          [submittedVariant]: 1,
        },
        {
          headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
          },
        })
        .then((response) => {
          payload.stats = response.data;
          commit("SET_QUIZ_STATS", payload);
        })
        .then(() => {
          commit("SUBMIT_ANSWER", payload);
          payload.isLoading = false;
          commit("SET_LOADING_STATUS", payload);
          pushAnalData(payload);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeQuizItemsOrder(context, payload) {
      context.commit('CHANGE_QUIZ_ITEMS_ORDER', payload)
    },

    setNewButtons(context, payload) {
      context.commit('SET_NEW_BUTTONS', payload)
    }
  },
  getters: {
    getCheckedCheckboxesById: (state) => (id) =>
      state.quizItems[id].buttons.filter((button) => button.isChecked),
  },
};

export default quiz;
