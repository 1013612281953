<template>
  <nav class="headerWrap">
    <div id="headerMain" class="header">
      <div class="header__logo" @click="scrollTop">
        <router-link :to="{ name: 'home' }" class="header__logo--desktop"><img src="@/Assets/Img/Logos/primary-logo.svg"
            alt="Combogesic logo" class="icon-logo" />
        </router-link>
      </div>
      <div class="header__right desktop">
        <nav>
          <ul>
            <li style="display: none;"></li>
            <li v-for="(route, index) in $router.options.routes" :key="route.name" v-if="route.name != 'home' && index <= 4"
              :class="{'active': route.name == $route.name,'submenu' : route.submenu,[`desktoplink${index}`] : true}">
              <router-link :to="route.path" class="routelink">
                <span v-html="route.name" class="routelink"></span>
              </router-link>
              <ul class="submenu">
                <li v-for="submenus in route.submenu">
                  <router-link :to="submenus.path" class="routelink">
                    <span v-html="submenus.name" class="routelink"></span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div class="header__right mobile">
        <button class="header__menuToggle" :class="{ active_menu: this.mobileMenuState }" @click="toggleMenu">
          <span class="sr-only" v-if="!mobileMenuState">expand</span>
          <span class="sr-only" v-if="mobileMenuState">collapse</span>
        </button>
      </div>
    </div>

    <div id="headerNav" class="header__collapseWrapper" :class="{
      'header__collapseWrapper  header__collapseWrapper--open':
        this.mobileMenuState,
    }">
      <nav>
        <ul class="header__dropdown" :class="{ isOpen }">
          <li style="display: none;"></li>
          <li v-for="(route, index) in $router.options.routes" :key="route.name" v-if="route.name != 'home' && index <= 4" :class="{'active': route.name == $route.name,'submenu' : route.submenu, [`mobilelink${index}`] : true}">
            
            <button @click="toggleSublist(index)" class="routelink mobilelink" v-if="route.submenu" aria-label="sub menu">
              <span v-html="route.name" class="routelink"></span>
            </button>

            <router-link :to="route.path" class="routelink" v-if="!route.submenu" @click="collapseMenu">
              <span v-html="route.name" class="routelink"></span>
            </router-link>

            <ul :class="{'opened': activeIndex === index, [`mobilesublink${index}`] : true}" class="submenu">
              <li v-for="submenus in route.submenu" :key="submenus.name">
                <router-link :to="submenus.path" class="routelink" @click="collapseMenu" >
                  <span v-html="submenus.name" class="routelink"></span>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="staticLink"><a href="/#/contact-us"><img src="@/Assets/Img/Icons/icon-connect-white.svg" alt="Connect Icon" />Connect With Hikma</a></li>
          <li class="staticLink"><a :href="urls.PI.link" target="_blank"><img src="@/Assets/Img/Icons/icon-pdf-white.svg" alt="PDF Icon" />{{ urls.PI.text }}</a></li>
          <li class="btnOrder"><a href="/#/resources/#product-ordering"><img src="@/Assets/Img/Icons/icon-cart.svg" alt="Cart Icon" />Order Product Today</a></li>
        </ul>
      </nav>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "Header",
  components: {},
  data() {
    return {
      activeIndex: null,
      isOpen: false,
      isScrolled: false,
      options: {
        container: "body",
        easing: "ease-in",
        offset: -150,
      },
    };
  },
  mixins: [ClickAnalytics],
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "mobileMenuState",
      "activeSection",
      "mainMenu",
    ]),
    routes() {
      return this.$router.options.routes;
    },
  },
  methods: {
    scrollFunction() {},

   toggleSublist(index) {
      document.getElementsByClassName("mobilelink"+index)[0].classList.toggle("opened");
      document.getElementsByClassName("mobilesublink"+index)[0].classList.toggle("opened");
    },
    scrollToTop() {
      this.$scrollTo("#head-section", 500);
    },
    toggleMenu() {
      this.$store.commit("TOGGLE_MOBILE_MENU", !this.mobileMenuState);

    },
    detectScrollYPosition() {
      this.isScrolled = window.pageYOffset > 0;
      if (this.mobileMenuState == true) {
        this.$store.commit("TOGGLE_MOBILE_MENU", !this.mobileMenuState);
      }
    },
    collapseMenu() {
      if (this.mobileMenuState == true) {
        this.$store.commit("TOGGLE_MOBILE_MENU", !this.mobileMenuState);
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    mobileMenuState() {
      const $body = document.querySelector("body");
      if (this.mobileMenuState) {
        $body.classList.add("popup-open");
      } else {
        $body.classList.remove("popup-open");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.detectScrollYPosition);
    window.addEventListener("scroll", this.scrollFunction);
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" src="./Header.scss" scoped></style>