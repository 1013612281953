<template>
  <section class="references">
    <div class="contentWrap">
      <h2 v-if="ReferenceList.length < 2">Reference:</h2>
      <h2 v-if="ReferenceList.length > 1">References:</h2>
      <ol>
        <li v-for="(item, index) in ReferenceList" v-bind:key="index" v-html="item"></li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReferenceSection",
  props : {
    ReferenceList: Array,
  }
};
</script>

<style lang="scss" src="./ReferenceSection.scss" ></style>    