<template src="./ContactUs.html"></template>
<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import { computed } from "vue";
import axios from 'axios';

export default {
  name: "ContactUs",
  components: {
    InnerHeroSection,
  },
  data() {
    return {
      invalidFirstname: false,
      invalidLastname: false,
      invalidphone: false,
      invalidemail: false,
      invalidjobtitle: false,
      invalidorganization: false,
      invalidinterested: false,
      invalidmessage: false,
      validFirstname: false,
      validLastname: false,
      validphone: false,
      validemail: false,
      validjobtitle: false,
      validorganization: false,
      validinterested: false,
      validmessage: false,
      grecaptcha:false, 
      HeroBoxData: {
          title: "Contact Us",
      },
      isCaptchaVerified: false,
      captchaToken: null,
      siteKey: '6LdtKVMqAAAAACBdTaTgmz9kE_mkBdcAQeEDk4cp', 
      grecaptchaLoaded: false
    }
  },
   mounted() {
    this.loadRecaptchaScript();
  },
  methods: {
    handleFocusOut() {
      const firstName = document.getElementById("firstname").value;
      const lastName = document.getElementById("lastname").value;
      const email = document.getElementById("email").value;
      const phone = document.getElementById("phone").value;
      const jobtitle = document.getElementById("jobtitle").value;
      const organization = document.getElementById("organization").value;
      const interested = document.getElementById("interested").value;
      const message = document.getElementById("message").value;
        const response = grecaptcha.getResponse();
      if (response.length === 0) {
        this.grecaptcha = true;
      }else{
        this.grecaptcha = false;
      }
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(emailPattern.test(email)){
        this.validemail = true;
        this.invalidemail = false;
      }else{
        this.validemail = false;
        this.invalidemail = true;
      }
      const phonePattern = /^\d{10}$/;
       if(phonePattern.test(phone)){
        this.validphone = true;
        this.invalidphone = false;
      }else{
        this.validphone = false;
        this.invalidphone = true;
      }
      this.validFirstname = firstName;
      this.validLastname = lastName;
      this.validjobtitle = jobtitle;
      this.validorganization = organization;
      this.validinterested = interested;
      this.validmessage = message;
       this.invalidFirstname = !firstName;
      this.invalidLastname = !lastName;
      this.invalidjobtitle = !jobtitle;
      this.invalidorganization = !organization;
      this.invalidinterested = !interested;
      this.invalidmessage = !message;

    },
    async submitForm() {
      const firstName = document.getElementById("firstname").value;
      const lastName = document.getElementById("lastname").value;
      const email = document.getElementById("email").value;
      const phone = document.getElementById("phone").value;
      const jobtitle = document.getElementById("jobtitle").value;
      const organization = document.getElementById("organization").value;
      const interested = document.getElementById("interested").value;
      const message = document.getElementById("message").value;
      const response = grecaptcha.getResponse();
      if (response.length === 0) {
        this.grecaptcha = true;
      }else{
        this.grecaptcha = false;
      }
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(emailPattern.test(email)){
          this.invalidemail = false;
      }else{
          this.invalidemail = true;
      }
      const phonePattern = /^\d{10}$/;
      if(phonePattern.test(phone)){
        this.invalidphone = false;
      }else{
        this.invalidphone = true;
      }
      console.log("phone",phone)
      console.log("invalidphone",this.invalidphone)
      console.log("phonePattern",phonePattern.test(phone))
      // Validation
      this.invalidFirstname = !firstName;
      this.invalidLastname = !lastName;
      this.invalidjobtitle = !jobtitle;
      this.invalidorganization = !organization;
      this.invalidinterested = !interested;
      this.invalidmessage = !message;

      if (this.invalidFirstname || this.invalidLastname || this.invalidemail || this.invalidphone || this.invalidjobtitle || this.invalidorganization || this.invalidinterested || this.invalidmessage) {
        return;
      }else{
          // Prepare data for submission
          let path = "/phpscript/index.php";
          try {
            // Make the API request
            const response = await axios.post(path, { 
              firstName: firstName,
              lastName: lastName,
              email: email,
              phone: phone,
              jobtitle: jobtitle,
              organization: organization,
              interested: interested,
              message: message
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
              document.getElementById('contactForm').reset();
              document.getElementById('contactForm').style.display = "none";
              document.getElementsByClassName('contactUs__formBox')[0].classList.add("submit");
              document.getElementsByClassName("successmessage")[0].style.display = "block";
          } catch (error) {
            console.error('Error submitting form:', error);
          }
      }
    },
     loadRecaptchaScript() {
      if (!this.grecaptchaLoaded) {
        const existingScript = document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]');
        if (existingScript) {
          existingScript.remove(); // Remove old script if it's already there
        }

        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
          this.grecaptchaLoaded = true;
        };
        document.head.appendChild(script); // Add the script to the head
      }
     }
  }
};
</script>

<style lang="scss" src="./ContactUs.scss" scoped></style>