<template src="./CookiePolicy.html"></template>

<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import Table from "@/Components/Tables/Table";
export default {
  name: "CookiePolicy",
  components: {
    InnerHeroSection,
    Table
  },
  data() {
    return {
      HeroBoxData: {
          title: "Cookie Policy",
      },
      cookiePolicy:{
        titleMain: "This policy may change from time to time, so please visit this page periodically to make sure you&rsquo;re up to date.",
        descriptionMain:'This policy provides Hikma Pharmaceuticals PLC&rsquo;s ("Hikma", "we", "us" or "our") cookie policy and describes how Hikma uses cookies and similar technology. For more information about privacy and how we process personal data more generally, please see our <a href="https://www.hikma.com/useful-links/privacy-policy/" target="_blank" class="link">Privacy Policy</a>.',
      },

      cookiePolicyData:{
        contentLoop: [
             {
              title: "What Are Cookies",
              description: "",
              listing:["A cookie is a small file that is sent to your browser from a web server and is stored on your computer/device. Cookies track, save and store information about your interactions and usage of the website. You can exercise your rights over some categories of cookies", "A cookie in no way gives us access to your computer/device or any information about you, other than information about how you use the website and the personal information you choose to share with us (including personal information you automatically share with us by way of your browser settings)"],
              numberlisting: [],
              description2: "",
              description3: "",
             },
             {
              title: "Use of Cookies",
              description: "<p class='pb-16'>COMBOGESIC IV&rsquo;s website uses cookies to better your experience while visiting the website. Cookies help us to analyze web traffic and identify which pages of our website are being used.</p><p>As required by legislation, where applicable this website uses a cookie control system, allowing you to give explicit permission or to deny the use of/saving of cookies on your computer/device.</p><p>In particular, we may use the following cookies on our website:</p>",
              listing:[],
              numberlisting: ["<strong>Essential cookies.</strong> These cookies are essential in order to enable you to move around the website and use its features.", "<strong>Functionality cookies.</strong> These cookies are used to identify you when you return to the website. This enables us to personalize the website content for you and remember your preferences (for example, your choice of language or region). These cookies can also help to keep your visit to the site secure.","<strong>Session cookies.</strong> These cookies allow us to identify your device as you use the website, so that you are not treated as a new visitor each time you go to another part of the website. Session cookies will be deleted from your computer/device when you close your browser.","<strong>Analytical or performance cookies.</strong> These cookies allow us to recognize and count the number of visitors to the website and collect information about how visitors use our website, for instance which pages visitors go to most often and if they get error messages from the web pages. These cookies don't collect information that identifies a visitor; all information these cookies collect is aggregated and therefore anonymous. It is only used to improve how our website works.","<strong>Targeting cookies.</strong> These cookies record your visit to the website, the pages you have visited and the links you have followed. We will use this information to make the website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose."],
              description2: "You can find more information about the individual cookies we use and the purposes for which we use them in the table below:",
              description3: "To the extent that any applicable laws consider the information collected by cookies and other similar technologies to be personal data, we will treat that information in accordance with our <a href='https://www.hikma.com/useful-links/privacy-policy/' target='_blank' class='link'>Privacy Policy</a>.",
             },

             {
              title: "Third Party Cookies",
              description: "Please note that third parties may also use cookies to assess the ways in which you make use of the website, over which we have no control. These third parties may include, for example, streaming video services and providers of external services like web traffic analysis services. These third-party cookies are likely to be analytical cookies, performance cookies or targeting cookies.",
              listing:[],
              numberlisting: [],
              description2: "",
              description3: "",
             },
             {
              title: "Acceptance and Rejection of Cookies",
              description: "<p>In most cases cookies cannot be deployed without consent. When you first enter our website, you will usually be shown a pop-up cookie notification banner. The banner informs you that our website uses cookies and similar technologies and gives you details and options around the use of cookies.</p> <p>You can also manage cookies via your browser settings (this will allow you to refuse the setting of all or some cookies) and your browser provider may ask you to confirm your settings. Note, however, that if you block all cookies (including essential cookies) via your browser settings you may lose some of the website&rsquo;s functionality and/or not be able to access all or parts of our website.</p><p class='highlight'>Details on how to disable cookies for common browsers are as follows:</p>",
              listing:[],
              numberlisting: [],
              description2: "",
              description3: "",
             },
             {
              title: "Contact Details",
              description: "<p>If you have any questions regarding the use of cookies on our website, please contact us by email at: <a href='mailto:privacypolicy@Hikma.com' class='link'>privacypolicy@Hikma.com</a>.</p> <p>This policy was last updated on Feb 15th 2023.</p>",
              listing:[],
              numberlisting: [],
              description2: "",
              description3: "",
             },
            ]
      },
      TableData1: {
        class: "tableContainer--first",
        tableTitle: "Necessary Cookies",
        thead: [
          {
            className:"tr-rowGray",
            columns: [
              { text: 'Cookie/Domain Type', className:'th-widthCommon leftBorderRadius' },
              { text: 'Provider', className:'th-widthCommon' },
              { text: 'Purpose', className:'th-widthOne' },
              { text: 'Duration', className:'th-widthCommon rightBorderRadius'},
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: 'CookieControl' }, { text: 'Civic Cookie' }, { text: 'Used to remember user choices regarding Cookies.' }, { text: '3 Months' }, 
            ],
          },
          {
            columns: [
              { text: 'UMB_UCONTEXT_C' }, { text: 'Umbraco' }, { text: 'Used by CMS to store an ID of the users&rsquo; session.' }, { text: '15 Minutes' },
            ]
          },
          {
            columns: [
              { text: 'UMB-XSRF-TOKEN' }, { text: 'Umbraco' }, { text: 'Used by CMS to pass authentication token used for antiforgery.' }, { text: 'Session' },
            ],
          },
          {
            columns: [
              { text: 'UMB-XSRF-V' }, { text: 'Umbraco' }, { text: 'Used to store the backoffice antiforgery token validation value.' }, { text: 'Session' },
            ]
          },
          {
            columns: [
              { text: 'UMB_UPDCHK' }, { text: 'Umbraco' }, { text: 'Used by CMS to check for Umbraco software updates.' }, { text: 'Session' },
            ]
          },
          {
            columns: [
              { text: 'disclaimer-*' }, { text: 'Hikma' }, { text: 'Used to determine if the disclaimer was already shown to the user.' }, { text: 'Session' },
            ]
          },
          {
            columns: [
              { text: '.AspNetCore.Mvc.Cookie' }, { text: 'ASP.NET CORE' }, { text: 'With this cookie, information or error messages in the website are stored. It is used for effective running of the website. Your personal data is not processed through this cookie.' }, { text: 'Session' },
            ]
          },
          {
            columns: [
              { text: '.AspNetCore.Antiforgery' }, { text: 'ASP.NET CORE' }, { text: 'ASP.NET Core looks for this cookie to find X-CSBE token.' }, { text: 'Session' },
            ]
          },
        ]
      },
      TableData2: {
        class: "tableContainer--second",
        tableTitle: "Analytics/Statistics Cookies",
        thead: [
          {
            className:"tr-rowGray",
            columns: [
              { text: 'Name', className:'th-widthCommon leftBorderRadius'  },
              { text: 'Provider', className:'th-widthCommon'  },
              { text: 'Purpose', className:'th-widthOne'  },
              { text: 'Duration', className:'th-widthCommon rightBorderRadius' },
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: '_gid' }, { text: 'Google Analytics' }, { text: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.' }, { text: '1 Day' }, 
            ],
          },
          {
            columns: [
              { text: '_gat_UA-*' }, { text: 'Google Analytics' }, { text: 'Used to provide technical monitoring, track user behavior and measure site performance.' }, { text: '1 Minute' },
            ]
          },
          {
            columns: [
              { text: '_ga_*' }, { text: 'Google Analytics' }, { text: 'Used to identify and track an individual session.' }, { text: '1 Year' },
            ],
          },
          {
            columns: [
              { text: '_ga' }, { text: 'Google Analytics' }, { text: 'Enables a service to distinguish one visitor from another.' }, { text: '1 Year' },
            ]
          },
          {
            columns: [
              { text: '_gcl_*' }, { text: 'Google Analytics' }, { text: 'Used to measure ad and campaign performance and conversion rates for Google ads on a site you visit. Cookies used for measuring conversion rates are not used to personalize ads.' }, { text: '3 Months' },
            ]
          },
          {
            columns: [
              { text: 'receive-cookie-depreciation' }, { text: 'Google' }, { text: 'Used to recieve Sec-Cookie-Depreciation' }, { text: '6 Months' },
            ]
          },
        ]
      },
      TableData3: {
        class: "tableContainer--third",
        tableTitle: "",
        thead: [
          
          {
            className:"tr-rowGray",
            columns: [
              
              { text: 'Cookie/Domain Type', className:'th-widthCommon leftBorderRadius'  },
              { text: 'Provider', className:'th-widthCommon'  },
              { text: 'Purpose', className:'th-widthOne'  },
              { text: 'Duration', className:'th-widthCommon rightBorderRadius' },
            ]
          },
        ],
        tbody: [
       
          {
            columns: [
              { text: 'Session<span>Collect</span>' }, { text: 'Google Analytics' }, { text: 'This Cookie is used to count how many times a website has been visited by different visitors—this is done by assigning the visitor an ID, so the visitor does not get registered twice.' }, { text: 'Session' }, 
            ],
          },
          {
            columns: [
              { text: 'Performance<span>VISITOR_INFO1_LIVE</span>' }, { text: 'Youtube.com' }, { text: 'This cookie tries to estimate the user&rsquo;s bandwidth on pages with integrated YouTube videos.' }, { text: '180 Days' },
            ]
          },
          {
            columns: [
              { text: 'Functional<span>CONSENT</span>' }, { text: 'Youtube.com' }, { text: 'Used to detect if the visitor has accepted the marketing category.' }, { text: '2 Years' },
            ],
          },
          {
            columns: [
              { text: 'Functional<span>DEVICE_INFO</span>' }, { text: 'Youtube.com' }, { text: 'Track user interaction with embedded content.' }, { text: '180 Days' },
            ]
          },
          {
            columns: [
              { text: 'Functional<span>LAST_RESULT_ENTRY_KEY</span>' }, { text: 'Youtube.com' }, { text: 'Stores the user&rsquo;s video player preferences using embedded YouTube video' }, { text: 'Session' },
            ]
          },
          {
            columns: [
              { text: 'Functional<span>LogsDatabaseV2</span>' }, { text: 'Youtube.com' }, { text: 'YouTube' }, { text: 'Persistent' },
            ]
          },
          {
            columns: [
              { text: 'Functional<span>YSC</span>' }, { text: 'Youtube.com' }, { text: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen' }, { text: 'Session' },
            ]
          },
          {
            columns: [
              { text: 'Functional<span>ytidb::LAST_RESULT_ENTRY_KEY</span>' }, { text: 'Youtube.com' }, { text: 'Stores the user&rsquo;s video player preferences using embedded YouTube Video' }, { text: 'Persistent' },
            ]
          },
          {
            className: "pb-0",
            columns: [
              { text: 'Functional<span>YtidbMeta#databases</span>' }, { text: 'Youtube.com' }, { text: '', className:'hideMe' }, { text: 'Persistent' },
            ]
          },
        ]
      },
    }
  }
};
</script>

<style lang="scss" src="./CookiePolicy.scss" scoped></style>