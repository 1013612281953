<template src="./Patents.html"></template>

<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
export default {
  name: "Patents",
  components: {
    InnerHeroSection,
  },
  data() {
    return {
      HeroBoxData: {
          title: "Patents",
      },
      PatentsData:{
        description: "<p class='pb-20'>This website is provided to satisfy the virtual marking provisions set forth in 35 U.S.C. Section 287. </p> <p>COMBOGESIC® IV (acetaminophen and ibuprofen) injection, the process of its manufacture, or its use, may be covered by one or more US Patents, including 11213498, 11219609, 11389416, and 11446266. Additional patents may be issued and additional patent applications may be pending.</p>"
      }
    }
  }
};
</script>

<style lang="scss" src="./Patents.scss" scoped></style>