<template>
  <div id="app" :class="{ screentest: userAgent.screentest, isIE: isIE, homePage }" >
    <div :class="{ 'main-wrapp': true, 'static-isi': hideIsi }" v-if="$route.name != 'Restricted'">
      <header>
        <header-top />
        <header-block />
      </header>
      <main>
        <router-view />
      </main>      
      <Isi :hideElement="hideIsi" :fixed="true" />
      <Isi :static="true" />
      <rotate-lock v-if="userAgent.orientation === 'landscape' && userAgent.device.isPhone"></rotate-lock>
      <footer>
        <brand-footer></brand-footer>
      </footer>
    </div>
     <div class="main-wrapp" v-if="$route.name == 'Restricted'">
    <main>
        <router-view />
      </main>  
  </div>
  </div>
 
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import lockScroll from "@/mixins/lock-scroll";
import handleResize from "@/mixins/handleResize";
import handleOrientation from "@/mixins/handleOrientation";
import fixSafariOffset from "@/mixins/fixSafariOffset";
import { NoParentScrollMixin } from "@/mixins/NoParentScrollMixin";
import HeaderBlock from "@/Components/Layouts/Header/Header";
import HeaderTop from "@/Components/Layouts/Header/HeaderTop";
import BrandFooter from "@/Components/Layouts/Brand/BrandFooter";
import rotateLock from "@/Components/common-blocks/rotate-lock/rotate-lock";
import Isi from "@/Components/Blocks/Isi/Isi";


Vue.mixin(lockScroll);

export default {
  name: "App",
  components: {
    "rotate-lock": rotateLock,
    "header-block": HeaderBlock,
    "header-top": HeaderTop,
    BrandFooter,
    basePath: process.env.BASE_URL,
    Isi
  },
  beforeMount() {
    this.$store.commit(
      "UPDATE_SCREENTEST",
      this.$route.query.screentest || process.env.VUE_APP_SCREENTEST
    );
  },
  mixins: [
    handleResize,
    handleOrientation,
    fixSafariOffset,
    NoParentScrollMixin,
  ],
  data() {
    return {
      onBottom: false,
      pageBottomPosition: null,
      hideIsi: false,
      showSLL: true,
      showQuestion: true,
      showQuestionOne: true,
      innerpageisi: false,
    };
  },

  computed: {
    ...mapState(["userAgent"]),
    isIE() {
      return this.userAgent.browser.name === "ie";
    },
    orientation() {
      return this.userAgent.orientation;
    },
    brandFooterHeight() {
      return this.userAgent.width < 1024 ? 38 : 50;
    },
    routes() {
      return this.$router.options.routes;
    },
    homePage() {
      return this.$route.path === '/';
    },
  },
  methods: {
    closeMenu() {
      this.$store.commit("TOGGLE_MOBILE_MENU", false);
    },
    checkOnBottom() {
      this.hideIsi =
        document.querySelector(".isi").getBoundingClientRect().top >=
        document.querySelector(".isi.isi-static").getBoundingClientRect().top -
        this.brandFooterHeight;
    },
    calcVH() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    GTMEvents() {
      const sections = [
        { Header: document.querySelector(".headerWrap") },
        { Footer: document.querySelector(".brandFooter") },
      ];

      const isInViewport = (section) => {
        const rect = section.getBoundingClientRect();
        return (
          rect.bottom > 0 &&
          rect.right > 0 &&
          rect.left <
          (window.innerWidth || document.documentElement.clientWidth) &&
          rect.top <
          (window.innerHeight / 2 ||
            document.documentElement.clientHeight / 2)
        );
      };
    },
  },
  updated() {
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    document.addEventListener('scroll', () => {
      this.checkOnBottom();
    });
    if (window.innerWidth > 320) {
      window.addEventListener("scroll", this.scrollFunction);
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    orientation() {
      this.closeMenu();
      this.GTMEvents();
    },
    $route(to, from) {
      this.handleResize();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" src="./Styles/Main.scss"></style>