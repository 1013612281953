import { ScrollTrigger } from "gsap/ScrollTrigger";

const ScrollDepthAnalytics = {
  data() {
    return {
      triggers: [],
    };
  },
  methods: {
    initScrollDepthAnalytics() {
        const pageHeight = document.getElementsByClassName("main-wrapp")[0].scrollHeight;
        const pagePartHeight = pageHeight / 10
        if(this.$route.name == "home"){
            var pagename = "landing";
        } else if(this.$route.name == "John"){
            var pagename = "patient-john";
        } else {
            var pagename = "patient-lee";
        }
      for (let i = 1; i <= 10; i++) {  
        this.triggers.push(
          ScrollTrigger.create({
            trigger: "body",
            start: pagePartHeight * i - window.screen.height - 300,
            once: true,
            onEnter: () => {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: "scroll-depth",
                  "page-name": `${pagename}`,
                  "scroll-depth": `${i * 10}%`,
                });
              }
            },
          })
        );
      }
    },
  },
  destroyed() {
    this.triggers.forEach((item) => {
      item.kill();
    });
    this.triggers = [];
  },
};

export default ScrollDepthAnalytics;
