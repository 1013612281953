<template src="./FAQs.html"></template>

<script>
import InnerHeroSection from "@/Components/Blocks/InnerHeroSection/InnerHeroSection";
import ReferenceSection from "@/Components/Blocks/Reference/ReferenceSection";
export default {
  name: "FAQs",
  
  components: {
    InnerHeroSection,
    ReferenceSection
  },
  data() {
    return {
      HeroBoxData: {
          title: "COMBOGESIC<sup>®</sup> IV FAQ<span class='text-lowercase'>s</span>",
      },
      faqData1:[
        {
          title:"When was COMBOGESIC® IV approved by the FDA?",
          description:"COMBOGESIC® IV was approved in October 2023 for use in adults where an intravenous route of administration is considered clinically necessary for<sup>1</sup>:",
          listing: [
            "the relief of mild to moderate pain",
            "the management of moderate to severe pain as an adjunct to opioid analgesics",
          ],
        },
        {
          title:"What is the mechanism of action (MOA) of COMBOGESIC® IV?",
          description:"<span>COMBOGESIC® IV contains acetaminophen and ibuprofen as active drug substances.<sup>1</sup> </span> <span>Acetaminophen is a non-opiate, non-salicylate analgesic. The precise mechanism of the analgesic properties of acetaminophen is not established but is thought to primarily involve central actions.<sup>1</sup></span> <span>Ibuprofen is a nonsteroidal anti-inflammatory drug (NSAID). Its mechanism of action for analgesia, like that of other NSAIDs, is not completely understood, but involves inhibition of cyclooxygenase (COX-1 and COX-2). Ibuprofen is a potent inhibitor of prostaglandin synthesis in vitro. Prostaglandins sensitize afferent nerves and potentiate the action of bradykinin in inducing pain in animal models. Prostaglandins are mediators of inflammation. Because ibuprofen is an inhibitor of prostaglandin synthesis, its mode of action may be due to a decrease of prostaglandins in peripheral tissues.<sup>1</sup></span>",
          listing: []
        },
        {
          title:"What are the drug interactions for COMBOGESIC® IV?",
          description:"A number of known or potential interactions between COMBOGESIC IV® and other drugs/drug classes exist. COMBOGESIC IV® may interact with drugs that interfere with hemostasis including warfarin, aspirin, selective serotonin reuptake inhibitors (SSRIs), and serotonin norepinephrine reuptake inhibitors (SNRIs), angiotensin converting enzyme (ACE) inhibitors, angiotensin receptor blockers (ARBs), beta-blockers, diuretics, digoxin, lithium, methotrexate, cyclosporine, other NSAIDs and salicylates, and pemetrexed.<sup>1</sup>",
          listing: []
        },
        {
          title:"How do I order COMBOGESIC® IV for my patient(s)?",
          description:"<span>Ordering is simple. COMBOGESIC® IV comes in a 10-vial carton and a minimum order quantity is one carton.</span> <a href='/#/resources/#product-ordering'>Download Ordering Information</a>",
          listing: []
        },
      ],
      faqData2:[
        {
          title:"What is the recommended dosage of COMBOGESIC® IV?",
          description:"<span>For adult patients weighing greater than or equal to 50 kg (actual body weight): The recommended dosage of COMBOGESIC® IV is one vial (100 mL; acetaminophen 1,000 mg/ibuprofen 300 mg) administered as a 15-minute infusion every 6 hours, as necessary.<sup>1</sup></span> <span>For adult patients weighing less than 50 kg (actual body weight): The recommended dosage is 15 mg/kg acetaminophen and 4.5 mg/kg ibuprofen, administered as a 15-minute infusion every 6 hours, as necessary. This equates to a maximum single dose of 750 mg acetaminophen and 225 mg ibuprofen (discard remaining medicine in vial), and a total daily dose of 3,000 mg (3 g) acetaminophen and 900 mg ibuprofen.<sup>1</sup></span>",
          listing: [
            "Use the lowest effective dosage for the shortest duration consistent with individual patient treatment goals<sup>1</sup>",
            "Do not exceed the maximum total daily dose of COMBOGESIC® IV (4,000 mg acetaminophen and 1,200 mg ibuprofen) in 24 hours<sup>1</sup>",
          ],
        },
        {
          title:"How do I administer COMBOGESIC® IV?",
          description:"",
          listing: [
            "Administer as a 15-minute intravenous infusion<sup>1</sup>",
            "Do not mix other medications with the COMBOGESIC® IV vial or infusion device<sup>1</sup>",
            "As for all solutions for infusion presented in glass vials, monitor closely, particularly at the end of infusion, regardless of administration route, in order to avoid air embolism. This applies particularly for central route infusion<sup>1</sup>",
            "To decrease the likelihood of bung fragmentation or the bung being forced into the vial, use a syringe or giving set with a diameter equal to or below 0.8 mm for solution sampling and ensure that the bung is pierced at the location specifically designed for needle introduction (where the thickness of the bung is the lowest)<sup>1</sup>",
            "The entire 100 mL container of COMBOGESIC® IV is not intended for use in patients weighing less than 50 kg. For doses less than 1,000 mg acetaminophen and 300 mg ibuprofen, the appropriate dose must be withdrawn from the container and placed into a separate container prior to administration. Using aseptic technique, withdraw the appropriate dose (weight-based) from an intact sealed COMBOGESIC® IV container and place the measured dose in a separate empty, sterile container (e.g., glass bottle, plastic intravenous container, or syringe) for intravenous infusion to avoid the inadvertent delivery and administration of the total volume of the commercially available container. COMBOGESIC® IV is supplied in a single-dose container and the unused portion must be discarded<sup>1</sup>"
          ]
        },
      ],
      faqData3:[
        {
          title:"What was the design of the clinical trial for COMBOGESIC® IV?",
          description:"<span>COMBOGESIC® IV was studied in a Phase 3, placebo-controlled, prospective, randomized, double-blind, factorial design trial comparing the analgesic efficacy and safety of COMBOGESIC® IV (n=75/276) with acetaminophen alone (n=75/276), ibuprofen alone (n=76/276) and placebo (n=50/276), after bunionectomy surgery.<sup>1</sup></span> <span>The primary efficacy endpoint was the time-adjusted Sum of Pain Intensity Differences over 48 hours (SPID<sub>48</sub>) and analyzed with each pre-rescue Visual Analogue Scale (VAS) carried forward up to 2 hours. An analysis of covariance was used for the primary efficacy analysis with treatment as the fixed effect and baseline pain intensity score as the covariate on the intent-to-treat population.<sup>1</sup></span>"
        },
        {
          title:"What patients were studied in the clinical trial for COMBOGESIC® IV?",
          description:"The demographic and baseline characteristics of the 276 eligible patients were balanced between the treatment groups with the majority of patients being female (82%) and white (62%) with a mean (SD) age of 42.4 (12.2) years.<sup>1</sup>"
        },
      ]
    }
  },

  mounted() {
    let accordionBtn = document.getElementsByClassName("faqTitle");
    const panels = document.getElementsByClassName("panel");

    // Set maxHeight of all panels to 0px by default
    for (let i = 0; i < panels.length; i++) {
        panels[i].style.maxHeight = "0px";
    }

    for (let i = 0; i < accordionBtn.length; i++) {
        accordionBtn[i].addEventListener("click", function () {
            this.classList.toggle("active");
            const panel = this.nextElementSibling;
            if (panel.style.maxHeight === "0px" || panel.style.maxHeight === "") {
                panel.style.maxHeight = panel.scrollHeight + "px";
            } else {
                panel.style.maxHeight = "0px";
            }
        });
    }

    this.checkAccordionHeightAfterWindowResize = () => {
        for (let i = 0; i < accordionBtn.length; i++) {
            const panel = accordionBtn[i].nextElementSibling;
            if (accordionBtn[i].classList.contains("active")) {
                panel.style.maxHeight = panel.scrollHeight + "px";
            } else {
                panel.style.maxHeight = "0px";
            }
        }
    };

    window.addEventListener("resize", this.checkAccordionHeightAfterWindowResize);
    this.showResponseTrue = this.defaultOpened;
},
beforeDestroy() {
    window.removeEventListener("resize", this.checkAccordionHeightAfterWindowResize);
}
};
</script>

<style lang="scss" src="./FAQs.scss" scoped></style>