<template>
  <section class="HomePageNavSection">
    <div class="hideMobile">
      <div class="HomePageNavSection__row">
        <div class="HomePageNavSection__box" v-for="(item, index) in HomePageNavData" v-bind:key="index" :class="'HomePageNavSection__box'+index">
              <div class="HomePageNavSection__image">
                <img :src="item.icon" :alt="item.title"/>
              </div>
              <div class="HomePageNavSection__info">
                <h3 v-html="item.title"></h3>
                <p v-html="item.description"></p>
                <a target="_blank" :href="item.link" v-if="$route.name ==='Analgesic Efficacy'" class="orange-text">
                  <span v-html="item.linkText"></span><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" v-if="index !=2" /><img src="@/Assets/Img/Icons/icon-arrow-right-circle1.svg" alt="Right arrow" v-if="index ==2" />
                </a>
                <a :href="item.link" v-if="$route.name !=='Analgesic Efficacy'" class="white-text">
                  <span v-html="item.linkText"></span><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" v-if="index !=2" /><img src="@/Assets/Img/Icons/icon-arrow-right-circle1.svg" alt="Right arrow" v-if="index ==2" />
                </a>
              </div>
          </div>
      </div>
    </div>

    <div class="hideDesktop">
      <div class="HomePageNavSection__row">
        <VueSlickCarousel v-bind="settings">
          <div class="slide"  v-for="(item, index) in HomePageNavData" v-bind:key="index">
        <div class="HomePageNavSection__box" :class="'HomePageNavSection__box'+index">
              <div class="HomePageNavSection__image">
                <img :src="item.icon" :alt="item.title"/>
              </div>
              <div class="HomePageNavSection__info">
                <h3 v-html="item.title"></h3>
                <p v-html="item.description"></p>
                <a target="_blank" :href="item.link" v-if="$route.name ==='Analgesic Efficacy'" class="orange-text">
                  <span v-html="item.linkText"></span><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" v-if="index !=2" /><img src="@/Assets/Img/Icons/icon-arrow-right-circle1.svg" alt="Right arrow" v-if="index ==2" />
                </a>
                <a :href="item.link" v-if="$route.name !=='Analgesic Efficacy'" class="white-text">
                  <span v-html="item.linkText"></span><img src="@/Assets/Img/Icons/icon-arrow-right-circle.svg" alt="Right arrow" v-if="index !=2" /><img src="@/Assets/Img/Icons/icon-arrow-right-circle1.svg" alt="Right arrow" v-if="index ==2" />
                </a>
              </div>
          </div>
        </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  name: "HomePageNavSection",
  mixins: [ClickAnalytics],
  props: {
    HomePageNavData: Array,
  },
  components: {
    VueSlickCarousel,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      carousels: [],
      settings: {
        dots: true,
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        sliderPageIndex: 0,
        initialSlide: 0
      },
    }
  }
};
</script>

<style lang="scss" src="./HomePageNavSection.scss" scoped></style>