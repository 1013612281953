<template>
  <section class="AcutePainManagementSection">
      <div class="AcutePainManagementSection__intro">
        <div class="AcutePainManagementSection__introLeft">
          <h3 class="heading" v-html="AcutePainManagementData.Title"></h3>
        </div>
        <div class="AcutePainManagementSection__introRight">
          <h4 class="subHeading" v-html="AcutePainManagementData.Subtitle"></h4>
          <p class="des" v-html="AcutePainManagementData.Description"></p>          
        </div>
      </div>
      <div class="AcutePainManagementSection__understanding">
        <div class="AcutePainManagementSection__understandingBoxes">
          <div class="AcutePainManagementSection__understandingBox" v-for="(item, index) in AcutePainManagementData.Boxsection" v-bind:key="index" :class="'AcutePainManagementSection__understandingBox'+index">
            <div class="AcutePainManagementSection__understandingBoxLeft">
              <h4 class="subHeading" v-html="item.title"></h4>
              <p class="des" v-html="item.description"></p>
            </div>
            <div class="AcutePainManagementSection__understandingBoxRight">
              <div class="infoBox">
                <div class="infoBox__title" v-html="item.listingtitle"></div>
                <div class="infoBox__content">
                  <ul>
                    <li v-for="(item, index) in item.listing" v-html="item" v-bind:key="index"></li>
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="callOutBox">
          <div class="callOutBox__icon"><img src="@/Assets/Img/Icons/icon-pain-model-badge.svg" alt="" /></div>
          <div class="callOutBox__percentage"><img :src="AcutePainManagementData.Boximage" :alt="AcutePainManagementData.Boxtitle"/></div>
          <p v-html="AcutePainManagementData.Boxtitle"></p>
        </div>
    </div>      
    <div class="quoteBoxWrapper">
      <div class="quoteBox">
        <p v-html="AcutePainManagementData.Quotedata"></p>
       <span v-if="AcutePainManagementData.QuateCompany" v-html="AcutePainManagementData.QuateCompany"></span>
      </div>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "AcutePainManagementSection",
  mixins: [ClickAnalytics],
  props: {
    AcutePainManagementData: Object,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./AcutePainManagementSection.scss" scoped></style>