<template>
  <section class="MultimodalAnalgesiaSection">
    <div class="MultimodalAnalgesiaSection__content">
      <h3 class="heading" v-html="MultimodalAnalgesiaData.Title"></h3>
      <div class="MultimodalAnalgesiaSection__intro">
        <div class="MultimodalAnalgesiaSection__introLeft">
          <h4 class="subHeading" v-html="MultimodalAnalgesiaData.Subtitle"></h4>
          <p class="des" v-html="MultimodalAnalgesiaData.Description"></p>
        </div>
        <div class="MultimodalAnalgesiaSection__introRight">
          <span v-html="MultimodalAnalgesiaData.listingtitle"></span>
            <ul class="list">
              <li v-for="(item, index) in MultimodalAnalgesiaData.listing" v-html="item" v-bind:key="index"></li>
            </ul>
        </div>
      </div>
      <div class="callOutBox">
        <div class="callOutBox__icon"><img src="@/Assets/Img/Icons/icon-pain-model-badge.svg" alt="" /></div>
        <p v-html="MultimodalAnalgesiaData.Boxtitle"></p>
      </div>
    </div>
  </section>
</template>

<script>
import ClickAnalytics from "@/mixins/ClickAnalytics";
export default {
  name: "MultimodalAnalgesiaSection",
  mixins: [ClickAnalytics],
  props: {
    MultimodalAnalgesiaData: Object,
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  }
};
</script>

<style lang="scss" src="./MultimodalAnalgesiaSection.scss" scoped></style>